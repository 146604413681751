import { RecycleImage } from "../../../../assets/img/img";
import { ButtonImg } from "../../../../assets/Button/buttons";

export const PRODUCT_LINE_STRUCTURE = [
  {
    direction: "left",
    img: RecycleImage.ProductLine.ProductLine1,
    title: "WIMPLE BIN - M",
    dscription: "일상생활 속 생활 폐기물 자동 분류",
    btn1: ButtonImg.recycle2,
    btn2: ButtonImg.recycle2Hover,
    link1: "https://drive.google.com/file/d/1E0Q-wyautDzTm0Z55rlYKP9gRdkBsyW1/view?usp=sharing",
    link2: "https://drive.google.com/file/d/1LGFwx5E4uJhKtzcxyu_ZJPwdn_6PGKS0/view?usp=sharing",
  },
  {
    direction: "right",
    img: RecycleImage.ProductLine.ProductLine2,
    title: "WIMPLE BIN - P",
    dscription: "공공장소에서의 1차적 재활용 가능 자원 선별",
    btn1: ButtonImg.recycle3,
    btn2: ButtonImg.recycle3Hover,
    link1: "https://drive.google.com/file/d/1VIX0_okTN3jsh7L_mQH0bLzymrpxeKs_/view?usp=sharing",
    link2:
      "https://venture.g2b.go.kr:8321/vn/product/selectProductDtl.do?detailProductNm=%C0%E7%C8%B0%BF%EB%C7%B0%C0%DA%B5%BF%C8%B8%BC%F6%B1%E2&detailProductNo=4813150501&ntcePerdOrdnrytmCd=0001&prdctIdntNo=25492712&srchValue=??&upCtgrNo=SEARCH&vntrCorpDprmsOrd=1&vntrCorpDprmsReqSno=2&vntrCorpNm=%C1%D6%BD%C4%C8%B8%BB%E7+%BC%AD%B8%A3&vntrCorpNo=CN0100000830508",
  },
  {
    direction: "left",
    img: RecycleImage.ProductLine.ProductLine3,
    title: "WIMPLE EDU",
    dscription: "우리 아이들이 살아갈 미래 환경을 위한 재활용 교육",
    btn1: ButtonImg.recycle4,
    btn2: ButtonImg.recycle4Hover,
    link1: "https://drive.google.com/file/d/1cC8T8Bg5ES7uFaF4Mk7hygppXOm1X5py/view?usp=sharing",
    link2: "https://venture.g2b.go.kr:8321/vn/product/selectProductDtl.do",
  },
];
