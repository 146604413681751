/** @jsxImportSource @emotion/react */
import useBasePadding from "../../../../hooks/useBasePadding";
import useResponsive from "../../../../hooks/useResponsive";
import { SECTION6_THESIS_STRUCTURE } from "./structure";

const texts = {
  title: `CAPTOS에 대한 논문을 확인해보세요.`,
  titleMobile: `CAPTOS에 대한 논문을
확인해보세요.`,
};
export default function Section6() {
  const { GOLBAL_PADDING } = useBasePadding();
  const { isPc, isMobile } = useResponsive();
  return (
    <div css={wrapper(GOLBAL_PADDING, !isPc)}>
      {!isPc ? (
        <pre css={title(!isPc)}>{texts.titleMobile}</pre>
      ) : (
        <pre css={title(!isPc)}>{texts.title}</pre>
      )}
      <div
        css={{
          marginBottom: "200px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {SECTION6_THESIS_STRUCTURE.map((item) => {
          return (
            <a css={thesisBox(!isPc, isMobile)} href={item.link} target="_">
              <p css={yearStyle(!isPc)}>{item.index}</p>
              {isPc && <p css={indexStyle(!isPc)}>{item.years}</p>}
              <p css={titleStyle(!isPc)}>{item.title}</p>
            </a>
          );
        })}
      </div>
    </div>
  );
}

const wrapper = (padding: string, isMobile: boolean) => ({
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
  paddingLeft: padding,
  paddingRight: padding,
  background: "black",
  marginTop: isMobile ? "100px" : "200px",
});

const title = (isMobile: boolean) => ({
  color: "white",
  fontSize: isMobile ? "24px" : "32px",
  fontWeight: "600",
  marginBottom: isMobile ? "15px" : "50px",
  textAlign: isMobile ? ("center" as const) : undefined,
});

const thesisBox = (isNotPc: boolean, isMobile: boolean) => ({
  display: "flex",
  gap: isNotPc ? "24px" : "50px",
  width: isNotPc ? (isMobile ? "285px" : "450px") : "1280px",
  height: isNotPc ? "40px" : "80px",
  padding: isNotPc ? "0 12px" : "0 50px",
  alignItems: "center",
  borderRadius: "5px",
  background: "#3D3D3D",
  marginBottom: "10px",
  textDecoration: "none",
  "&: hover": {
    background: "linear-gradient(90deg, #6DDCFF 0%, #7F60F9 100%)",
  },
  "&:hover p": {
    color: "black",
  },
});

const indexStyle = (isMobile: boolean) => ({
  color: "white",
  fontSize: isMobile ? "12px" : "20px",
  fontWeight: "600",
});

const yearStyle = (isMobile: boolean) => ({
  color: "white",
  fontSize: isMobile ? "18px" : "30px",
  fontWeight: "700",
});

const titleStyle = (isMobile: boolean) => ({
  color: "white",
  fontSize: isMobile ? "12px" : "18px",
  fontWeight: "400",
  whiteSpace: isMobile ? "nowrap" : "",
  overflow: isMobile ? "hidden" : "",
  textOverflow: isMobile ? "ellipsis" : "",
});
