import img1 from "../../../../assets/img/Home/Tinding/1.png";
import img2 from "../../../../assets/img/Home/Tinding/2.png";
import img3 from "../../../../assets/img/Home/Tinding/3.png";
import img4 from "../../../../assets/img/Home/Tinding/4.png";
import img5 from "../../../../assets/img/Home/Tinding/5.png";
import img6 from "../../../../assets/img/Home/Tinding/6.png";
import img7 from "../../../../assets/img/Home/Tinding/7.png";
import img8 from "../../../../assets/img/Home/Tinding/8.png";
import img9 from "../../../../assets/img/Home/Tinding/9.png";
import img10 from "../../../../assets/img/Home/Tinding/10.png";
import img11 from "../../../../assets/img/Home/Tinding/11.png";
import img12 from "../../../../assets/img/Home/Tinding/12.png";
import img13 from "../../../../assets/img/Home/Tinding/13.png";
import img14 from "../../../../assets/img/Home/Tinding/14.png";
import img15 from "../../../../assets/img/Home/Tinding/15.png";
import img16 from "../../../../assets/img/Home/Tinding/16.png";
import img17 from "../../../../assets/img/Home/Tinding/17.png";
import img18 from "../../../../assets/img/Home/Tinding/18.png";
import img19 from "../../../../assets/img/Home/Tinding/19.png";
import img20 from "../../../../assets/img/Home/Tinding/20.png";

export const TindingData = [
  {
    title: "양산부산대병원, 카자흐스탄에 캡슐내시경 영상판독 기술 전수",
    date: "2022.6.5",
    link: "https://www.yna.co.kr/view/AKR20220603104100052?input=1195m",
    image: img1,
  },
  {
    title: "[혁신, 스타트업을 만나다] 인공지능이 쓰레기를 분리해서 자원으로 만든다 - 서르",
    date: "2022.6.15",
    link: "https://sports.khan.co.kr/bizlife/sk_index.html?art_id=202206150700003&sec_id=564001&pt=nv",
    image: img2,
  },
  {
    title: "카자흐스탄 의료기관과 양산부산대병원 MOU",
    date: "2022.10.31",
    link: "https://www.busan.com/view/busan/view.php?code=2022103118154915469",
    image: img3,
  },
  {
    title: "동서대, 산학협력 EXPO서 교육부 장관상 2건 수상해",
    date: "2022.11.15",
    link: "https://www.viva100.com/main/view.php?key=20221115010004550",
    image: img4,
  },
  {
    title: "부산상공회의Show 책자_지역유망스타트업 소개",
    date: "2022.12월호",
    link: "https://www.bcci.or.kr/kr/index.php?pCode=startuphit&mode=view&idx=6350",
    image: img5,
  },
  {
    title: "박윤규 과기정통부 2차관, 데이터 산업분야 혁신기업 시상",
    date: "2022.12.20",
    link: "https://www.enewstoday.co.kr/news/articleView.html?idxno=1624267",
    image: img6,
  },
  {
    title: "아키테코그룹, '스마트 그린·ESG 플랫폼'으로 단계별 넷제로 목표 실현",
    date: "2023.4.11",
    link: "https://www.ekn.kr/web/view.php?key=20230411010002713",
    image: img7,
  },
  {
    title: "양산부산대병원 ‘내시경 이미지 합성데이터’ 구축 사업 착수",
    date: "2023.08.31",
    link: "http://www.gndomin.com/news/articleView.html?idxno=364436",
    image: img8,
  },
  {
    title: "쓰레기 인식해 분리수거 돕는 AI",
    date: "2023.8.31",
    link: "http://www.kidd.co.kr/news/233914",
    image: img9,
  },
  {
    title: "“캡슐 내시경이 찍은 영상, 30분 만에 분석하는 AI 개발”",
    date: "2023.10.18 ",
    link: "https://www.themedical.kr/news/articleView.html?idxno=1199",
    image: img10,
  },
  {
    title: "㈜서르, ‘폐기물자원순환전 RETECH2023’에서 인공지능 재활용 분리시스템 선보여",
    date: "2023.10.25",
    link: "http://www.kdpress.co.kr/news/articleView.html?idxno=123588",
    image: img11,
  },
  {
    title: "Introducing a smart AI recycler Wimple bin that keeps clean environment of Earth",
    date: "2023.10.27",
    link: "http://www.powerkoream.co.kr/news/articleView.html?idxno=1108297",
    image: img12,
  },
  {
    title: "똑똑한 AI 분리수거기, 지구지킴이 'Wimple Bin'",
    date: "2023.4분기",
    link: "http://m.powerkoreadaily.com/news/articleView.html?idxno=525501",
    image: img13,
  },
  {
    title: "동서대·서르, 첨단 AI기반 ‘재활용폐기물 분류기’ 개발·실증",
    date: "2023.11.07",
    link: "https://www.busan.com/view/busan/view.php?code=2023110716035922812",
    image: img14,
  },
  {
    title: "AI 데이터 활성화가 디지털 헬스케어시장 성장 이끈다",
    date: "2023.11.20",
    link: "https://www.mk.co.kr/news/it/10879193",
    image: img15,
  },
  {
    title: `부산시설공단, '공공기관 테스트베드 지원사업' 박차…"창업기업 동력 제고 견인"`,
    date: "2024.1.10",
    link: "https://www.gukjenews.com/news/articleView.html?idxno=2897261",
    image: img16,
  },
  {
    title: "한국생산성학회, ‘2024 춘계학술대회’ 성료…생산성 CEO 대상 진행",
    date: "2024.05.24",
    link: "https://digitalchosun.dizzo.com/site/data/html_dir/2024/05/24/2024052480226.html",
    image: img17,
  },
  {
    title: "부산대 교원창업자 이연주 교수, '대한민국 생산성 CEO 대상'",
    date: "2024.06.17",
    link: "https://www.viva100.com/main/view.php?key=20240617010005111",
    image: img18,
  },
  {
    title: "[일상을 바꾸는 스마트 기술①] AI 대전환 시대, 우리 일상은 어떻게 변할까",
    date: "2024.6.24",
    link: "https://kidd.co.kr/news/237192",
    image: img19,
  },

  {
    title: "경남중기청, 초격차 스타트업 현판 전달",
    date: "2024.06.26 ",
    link: "https://www.gnnews.co.kr/news/articleView.html?idxno=556309",
    image: img20,
  },
];
