/** @jsxImportSource @emotion/react */
import { useEffect, Suspense } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";

export default function BaseLayout() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  //모바일 부분 아울렛 옵셔널 필요 할거 같기도?
  return (
    <>
      <Header />
      <Suspense fallback={null}>
        <section css={wrapper}>
          <Outlet />
        </section>
      </Suspense>
      <Footer />
    </>
  );
}

const wrapper = {
  // width: "calc(100vw - 32px)",
  display: "flex",
  justifyContent: "center",
  background: "black",
};
