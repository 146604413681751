/** @jsxImportSource @emotion/react */

import { MedicalImage } from "../../../../assets/img/img";
import useBasePadding from "../../../../hooks/useBasePadding";
import useResponsive from "../../../../hooks/useResponsive";

const texts = {
  title: `인공지능을 통한
캡슐 내시경 판독 시간 단축
의료진을 보조하여 조기 진단에
기여합니다.`,
  contentPc: `캡슐 내시경은 소장을 검진하기 위한 유일한 내시경 검사 방법입니다.
하지만 캡슐 내시경의 병변을 놓치지 않고 판독하기 위해서는 10시간 이상 녹화된 영상을  
의료진이 지속적으로 영상을 시청하며 병변을 판독해야 합니다. 
이는 휴먼에러를 야기 할 수 있으며 판독을 시행하는 의료진의 경험과 주관에 따라 다른 결론이 날 여지가 있습니다.

서르는 다량의 캡슐 내시경 AI 데이터 학습을 통해 병변을 객관적으로 분석하고 정략적 중증도를 판단하여
판독의 어려움으로 캡슐 내시경 사용을 주저하는 의료진을 보조하여 환자에게 좋은 예후를 가져다줍니다.`,
  contentMobile: `캡슐 내시경은 소장을 검진하기 위한
유일한 내시경 검사 방법입니다.
하지만 캡슐 내시경의 병변을 놓치지 않고 판독하기 위해서는
10시간 이상 녹화된 영상을 의료진이 지속적으로 영상을 시청하며
병변을 판독해야 합니다. 
이는 휴먼에러를 야기 할 수 있으며 판독을 시행하는
의료진의 경험과 주관에 따라 다른 결론이 날 여지가 있습니다.

서르는 다량의 캡슐 내시경 AI 데이터 학습을 통해
병변을 객관적으로 분석하고 정략적 중증도를 판단하여
판독의 어려움으로 캡슐 내시경 사용을 주저하는 의료진을 보조하여
환자에게 좋은 예후를 가져다줍니다.`,
};

export default function Section1() {
  const { GOLBAL_PADDING } = useBasePadding();
  const { isMobile, isTablet, isPc } = useResponsive();
  return (
    <div css={wrapper(GOLBAL_PADDING, !isPc)}>
      {!isPc ? (
        <div css={isPcStyle(isPc)}>
          <pre css={title(!isPc)}>{texts.title}</pre>
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "30px",
            }}
          >
            <img
              css={
                !isMobile
                  ? {
                      width: "270px",
                      height: "250px",
                      marginLeft: "12px",
                    }
                  : {
                      width: "180px",
                      height: "auto",
                      marginLeft: "12px",
                    }
              }
              src={MedicalImage.Img1}
              alt="section1"
            />
            <pre css={description(!isPc)}>{texts.contentMobile}</pre>
          </div>
        </div>
      ) : (
        <div css={isTablet ? { maxWidth: "460px" } : textBox}>
          <p css={title(!isPc)}>
            인공지능을 통한 캡슐 내시경 판독 시간 단축
            <br /> 의료진을 보조하여 조기 진단에 기여합니다.
          </p>
          <pre css={description(!isPc)}>{texts.contentPc}</pre>
        </div>
      )}
      {isPc && (
        <div>
          <img css={{ width: "416px", height: "450px", marginLeft: "64px" }} src={MedicalImage.Img1} alt="section1" />
        </div>
      )}
    </div>
  );
}

const wrapper = (padding: string, isMobile: boolean) => ({
  display: "flex",
  flexDirection: isMobile ? ("column" as const) : ("row" as const),
  justifyContent: "center",
  alignItems: "center",
  paddingLeft: padding,
  paddingRight: padding,
  background: "black",
  marginTop: isMobile ? "100px" : "200px",
  padding: "75px",
});

const textBox = {
  maxWidth: "760px",
};

const title = (isMobile: boolean) => ({
  color: "white",
  fontSize: isMobile ? "24px" : "32px",
  fontWeight: "600",
  lineHeight: "38px",
  textAlign: isMobile ? ("center" as const) : undefined,
});

const description = (isMobile: boolean) => ({
  marginTop: "30px",
  color: "white",
  fontSize: isMobile ? "14px" : "18px",
  fontWeight: "400",
  lineHeight: isMobile ? "20px" : "27px",
  textAlign: isMobile ? ("center" as const) : undefined,
});

const isPcStyle = (isPc: boolean) => ({
  display: isPc ? "" : "flex",
  flexDirection: isPc ? undefined : ("column" as const),
  gap: isPc ? "" : "30px",
});
