/** @jsxImportSource @emotion/react */
import { MedicalIcons } from "../../../../assets/icon/icon";
import { MedicalImage } from "../../../../assets/img/img";
import useHandleHistory from "../../../../hooks/useHandleHistory";
import { ButtonImg } from "../../../../assets/Button/buttons";
import { useState } from "react";
import useResponsive from "../../../../hooks/useResponsive";

const texts = {
  Title: "딥러닝 기반 캡슐 내시경 영상 판독 플랫폼",
  ContentPc: `CAPTOS를 통해 캡슐 내시경에 인공지능을 적용하여 의료진을 대신하여
소장 질환의 판독을 용이하게 하고 빠른 진단을 가능하게 하여
조기 진료를 통해 질병의 진행을 예방하고자 합니다.`,
  ContentMobile: `CAPTOS를 통해 캡슐 내시경에 인공지능을 적용하여
의료진을 대신하여 소장 질환의 판독을 용이하게 하고
빠른 진단을 가능하게 하여 조기 진료를 통해 질병의
진행을 예방하고자 합니다.`,
};

export default function MedicalMain() {
  const { handleHistoryPush } = useHandleHistory();
  const [buttonSrc, setButtonSrc] = useState<string>(ButtonImg.contact1);
  const [button2Src, setButton2Src] = useState<string>(MedicalImage.EngBtn);
  const { isTablet, isMobile } = useResponsive();
  return (
    <div css={wrapper(isTablet, isMobile)}>
      <div css={{ display: "flex", flexDirection: "column" as const, gap: "15px" }}>
        <div>
          <img src={MedicalIcons.MedicalMain.MedcialLogo} alt="MedcialLogo" />
        </div>
        <p css={SubTItleText(isMobile)}>{texts.Title}</p>
        {isMobile ? (
          <pre css={contentText(isMobile)}>{texts.ContentMobile}</pre>
        ) : (
          <pre css={contentText(isMobile)}>{texts.ContentPc}</pre>
        )}
      </div>
      <div css={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <img
          onClick={() => {
            handleHistoryPush("/Contact");
          }}
          onMouseEnter={() => setButtonSrc(ButtonImg.contact1Hover)}
          onMouseLeave={() => setButtonSrc(ButtonImg.contact1)}
          src={buttonSrc}
          alt="contact"
          css={buttonImage}
        />
        <a href="http://captos.seoreu.com/" target="_blank" rel="noopener noreferrer">
          <img
            src={button2Src}
            alt="engBtn"
            css={buttonImage}
            onMouseEnter={() => setButton2Src(ButtonImg.eng호버버튼)}
            onMouseLeave={() => setButton2Src(MedicalImage.EngBtn)}
          />
        </a>
      </div>
    </div>
  );
}

const wrapper = (isTablet: boolean, isMobile: boolean) => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column" as const,
  height: "100vh",
  padding: isMobile ? "0 40px" : isTablet ? "0 100px" : "0 320px",
  position: "relative" as const,
  overflow: "hidden",
  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${MedicalImage.MedicalBackground})`,
  backgroundOrigin: "border-box",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  gap: "50px",
});

const SubTItleText = (isMobile: boolean) => ({
  fontSize: isMobile ? "16px" : "18px",
  fontWeight: 700,
  lineHeight: "21.48px",
  textAlign: "left" as const,
  color: "white",
});

const contentText = (isMobile: boolean) => ({
  fontSize: isMobile ? "15px" : "18px",
  fontWeight: 400,
  lineHeight: "27px",
  textAlign: "left" as const,
  color: "#fff",
});

const buttonImage = {
  width: "180px",
  height: "65px",
  cursor: "pointer",
};
