/** @jsxImportSource @emotion/react */
import useResponsive from "../../../../../hooks/useResponsive";
interface PropsTypes {
  img: string;
  titleIcon: string;
  title: string;
  description: string;
}
export default function Content({ img, titleIcon, title, description }: PropsTypes) {
  const { isPc, isTablet, isMobile } = useResponsive();
  return (
    <div css={row(isTablet, isMobile)}>
      <img css={!isPc ? { width: "285px" } : {}} src={img} alt={title} />
      <div css={column(!isPc)}>
        <div
          css={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "20px",
            justifyContent: isMobile ? "center" : undefined,
          }}
        >
          <div>
            <img src={titleIcon} alt={`${title}Icon`} css={pointImgStyle(!isPc)} />
          </div>
          <p css={titleStyle(!isPc)}>{title}</p>
        </div>
        {!isPc ? (
          <pre css={descriptionStyle(!isPc)}>{description}</pre>
        ) : (
          <pre css={descriptionStyle(!isPc)}>{description}</pre>
        )}
      </div>
    </div>
  );
}

const row = (isTablet: boolean, isMobile: boolean) => ({
  display: "flex",
  flexDirection: isMobile ? ("column" as const) : ("row" as const),
  gap: isMobile ? "12px" : isTablet ? "50px" : "85px",
  alignItems: "center",
  width: isMobile ? "285px" : "",
  boxSizing: "border-box" as const,
});

const column = (isMobile: boolean) => ({
  display: "flex",
  flexDirection: "column" as const,
  width: isMobile ? "285px" : "",
});

const titleStyle = (isMobile: boolean) => ({
  marginLeft: isMobile ? "10px" : "15px",
  color: "white",
  fontSize: isMobile ? "16px" : "20px",
  fontWeight: "700",
});

const pointImgStyle = (isMobile: boolean) => ({
  width: isMobile ? "81px" : undefined,
});

const descriptionStyle = (isMobile: boolean) => ({
  color: "white",
  fontSize: isMobile ? "14px" : "18px",
  fontWeight: "400",
  textAlign: isMobile ? ("center" as const) : undefined,
});
