/** @jsxImportSource @emotion/react */
import { HomeImg } from "../../../../assets/img/img";
import useHandleHistory from "../../../../hooks/useHandleHistory";
import useResponsive from "../../../../hooks/useResponsive";

export default function Solution() {
  const { handleHistoryPush } = useHandleHistory();
  const { isTablet, isMobile } = useResponsive();
  return (
    <div css={solutionStyle}>
      <div css={isSolutionContentMobile(isTablet, isMobile)}>
        <div css={solutionTextBox}>
          <p css={solutionTitle}>Solution</p>
          <p css={solutionSubTitle}>
            서르는 인공지능을 활용하여 사람이 살아가기 위해 해결해야하는 중요한 사회 문제를 해결할 솔루션을 만들어
            갑니다.
          </p>
        </div>
        <div css={isInformationMobile(isMobile)}>
          <div css={AiData}>
            <div css={isContentTextBoxMobile(isMobile)}>
              <p css={contentTexTitle}>AI DATA</p>
              <p css={contentTextDetail}>
                기업의 요구 사항에 맞는 효율적인 <br />
                데이터 가공 /알고리즘 개발 전략을 세우고
                <br />
                실행하며, 기업이 보다 효과적으로 사용할 수<br />
                있도록 결과물의 높은 품질을 보장합니다.
              </p>
              <p
                css={contentTextDetailButton}
                onClick={() => {
                  handleHistoryPush("/AiData");
                }}
              >
                자세히 알아보기 {">"}
              </p>
            </div>
          </div>
          <div css={recycle}>
            <div css={isContentTextBoxMobile(isMobile)}>
              <p css={contentTexTitle}>RECYCLE</p>
              <p css={contentTextDetail}>
                지속적으로 상승하는 폐기물 발생량과 처리 비용,인공지능
                <br />
                기반의 자동화로 분리수거 및<br />
                선별 프로세스 개선을 통해 분류 정확도를
                <br />
                향상하여 지속가능한 환경을 만들어 나갑니다.
              </p>
              <p
                css={contentTextDetailButton}
                onClick={() => {
                  handleHistoryPush("/Recycle");
                }}
              >
                자세히 알아보기 {">"}
              </p>
            </div>
          </div>
          <div css={medical}>
            <div css={isContentTextBoxMobile(isMobile)}>
              <p css={contentTexTitle}>MEDICAL</p>
              <p css={contentTextDetail}>
                인공지능을 통해 의료진의 복잡한 의료 문제를
                <br />
                해결하는 차세대 메디컬 AI를 개발하여
                <br />
                환자에게 좋은 예후를 가져다주는
                <br />
                혁신적인 솔루션을 제공합니다.
              </p>
              <p
                css={contentTextDetailButton}
                onClick={() => {
                  handleHistoryPush("/Medical");
                }}
              >
                자세히 알아보기 {">"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const solutionStyle = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column" as const,
  background: "black",
  minHeight: "100vh",
  height: "auto",
  gap: "50px",
};

const solutionContent = {
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
  height: "100%",
  padding: "100px 16.67%",
  gap: "100px",
};

const solutionContentTablet = {
  ...solutionContent,
  padding: "100px 7%",
};

const solutionContentMobile = {
  ...solutionContent,
  gap: "50px",
  padding: "100px 7%",
};

const isSolutionContentTablet = (isTablet: boolean) => (isTablet ? solutionContentTablet : solutionContent);
const isSolutionContentMobile = (isTablet: boolean, isMobile: boolean) =>
  isMobile ? solutionContentMobile : isSolutionContentTablet(isTablet);

const solutionTextBox = {
  display: "flex",
  flexDirection: "column" as const,
  gap: "15px",
};

const solutionTitle = {
  fontSize: "32px",
  fontWeight: 600,
  lineHeight: "38.19px",
  textAlign: "center" as const,
  color: "white",
};

const solutionSubTitle = {
  fontSize: "18px",
  fontWeight: 500,
  lineHeight: "27px",
  textAlign: "center" as const,
  color: "white",
};

const information = {
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  width: "100%",
  height: "100%",
  "@media(max-width:1100px)": {
    gridTemplateColumns: "repeat(1, 3fr)",
  },
};

const informationMobile = {
  ...information,
  gridTemplateColumns: "repeat(1, 3fr)",
};

const isInformationMobile = (isMobile: boolean) => (isMobile ? informationMobile : information);

const contentBox = {
  display: "flex",
  flexDirection: "column" as const,
  justifyContent: "center",
  alignItems: "center" as const,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundColor: "rgba(0, 0, 0, 0.70)",
  backgroundBlendMode: "overlay",
  zIndex: 0,
  "@media (max-width: 1100px)": {
    height: "500px",
  },
};

const AiData = {
  ...contentBox,
  backgroundImage: `url(${HomeImg.솔루션AiData})`,
};

const recycle = {
  ...contentBox,
  backgroundImage: `url(${HomeImg.솔루션Recycle})`,
};

const medical = {
  ...contentBox,
  backgroundImage: `url(${HomeImg.솔루션Medical})`,
};

const contentTextBox = {
  display: "flex",
  flexDirection: "column" as const,
  gap: "40px",
  justifyContent: "center",
  textAlign: "center" as const,
};

const contentTextBoxMobile = {
  ...contentTextBox,
  height: "450px",
};

const isContentTextBoxMobile = (isMobile: boolean) => (isMobile ? contentTextBoxMobile : contentTextBox);

const contentTexTitle = {
  ...solutionTitle,
  lineHeight: "35.8px",
};

const contentTextDetail = {
  fontSize: "15px",
  fontWeight: 400,
  lineHeight: "22.5px",
  textAlign: "center" as const,
  color: "white",
};

const contentTextDetailButton = {
  ...contentTextDetail,
  cursor: "pointer",
  display: "inline-block",
  ":hover": {
    textDecoration: "underline",
  },
};
