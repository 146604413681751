/** @jsxImportSource @emotion/react */

import { MedicalImage } from "../../../../../../assets/img/img";

interface PropsTypes {
  isClick: number;
  isMobile?: boolean;
}

const texts = {
  UlcerPc: `AI를 통해 소장 내 궤양, 용종, 림프관 확장, 출혈 검출이 가능합니다.`,
  UlcerMobile: `AI를 통해 소장 내 궤양, 용종,
림프관 확장, 출혈 검출이 가능합니다. `,
  MassPc: `막에서 융기한 병변으로 용종 등을 포함하여 크기는 다양함.`,
  MassMobile: `막에서 융기한 병변으로 용종 등을
포함하여 크기는 다양함.`,
  LymphangiectasiaPc: `AI를 통해 소장 내 궤양, 용종, 림프관 확장, 출혈 검출이 가능합니다.`,
  LymphangiectasiaMobile: `AI를 통해 소장 내 궤양, 용종,
림프관 확장, 출혈 검출이 가능합니다.`,
  BleedingPc: `혈관 손상으로 혈액이 혈관 밖으로 나움.`,
  BleedingMobile: `혈관 손상으로 혈액이 혈관 밖으로 나움.`,
};

export default function ImageComponent({ isClick, isMobile = false }: PropsTypes) {
  const isClickedComponent = (isClick: number) => {
    switch (isClick) {
      case 0:
        return (
          <div css={column(isMobile)}>
            <div css={row}>
              <img css={isMobile ? circleImgStyle : {}} src={MedicalImage.ai.LesionDetection1} alt="img1" />
              <div css={{ marginLeft: "20px", display: "flex", flexDirection: "column", gap: "5px" }}>
                <p css={title(isMobile)}>Ulcer 궤양</p>
                {!isMobile ? (
                  <pre css={description(isMobile)}>{texts.UlcerPc}</pre>
                ) : (
                  <pre css={description(isMobile)}>{texts.UlcerMobile}</pre>
                )}
              </div>
            </div>
            <div css={row}>
              <img css={isMobile ? circleImgStyle : {}} src={MedicalImage.ai.LesionDetection2} alt="img2" />
              <div css={{ marginLeft: "20px", display: "flex", flexDirection: "column", gap: "5px" }}>
                <p css={title(isMobile)}>Mass 용종 · 종양</p>
                {isMobile ? (
                  <pre css={description(isMobile)}>{texts.MassMobile}</pre>
                ) : (
                  <pre css={description(isMobile)}>{texts.MassPc}</pre>
                )}
              </div>
            </div>
            <div css={row}>
              <img css={isMobile ? circleImgStyle : {}} src={MedicalImage.ai.LesionDetection3} alt="img3" />
              <div css={{ marginLeft: "20px", display: "flex", flexDirection: "column", gap: "5px" }}>
                <p css={title(isMobile)}>Lymphangiectasia 림프관 확장</p>
                {isMobile ? (
                  <pre css={description(isMobile)}>{texts.LymphangiectasiaMobile}</pre>
                ) : (
                  <pre css={description(isMobile)}>{texts.LymphangiectasiaPc}</pre>
                )}
              </div>
            </div>
            <div css={row}>
              <img css={isMobile ? circleImgStyle : {}} src={MedicalImage.ai.LesionDetection4} alt="img4" />
              <div css={{ marginLeft: "20px", display: "flex", flexDirection: "column", gap: "5px" }}>
                <p css={title(isMobile)}>Bleeding 출혈</p>
                {isMobile ? (
                  <pre css={description(isMobile)}>{texts.BleedingMobile}</pre>
                ) : (
                  <pre css={description(isMobile)}>{texts.BleedingPc}</pre>
                )}
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div css={column(isMobile)}>
            <img src={MedicalImage.ai.TransitionClassification} alt="img5" />
          </div>
        );
      case 2:
        return (
          <div css={isMobile ? { ...column(isMobile), padding: "12px" } : { ...column(isMobile), padding: "100px" }}>
            <img src={MedicalImage.ai.VisualizationScale} alt="img5" />
          </div>
        );
    }
  };
  return <div css={wrapper(isMobile)}>{isClickedComponent(isClick)}</div>;
}
const wrapper = (isMobile: boolean) => ({
  background: "#3D3D3D80",
  maxWidth: isMobile ? "285px" : "632px",
});

const column = (isMobile: boolean) => ({
  display: "flex",
  flexDirection: "column" as const,
  justifyContent: "center",
  padding: isMobile ? "8px" : "20px",
  gap: isMobile ? "16px" : "30px",
  width: isMobile ? "285px" : "632px",
  height: isMobile ? "300px" : "700px",
  boxSizing: "border-box" as const,
});

const row = {
  display: "flex",
  alignItems: "center",
};

const title = (isMobile: boolean) => ({
  color: "white",
  fontWeight: "700",
  fontSize: isMobile ? "15px" : "20px",
  marginBottom: isMobile ? undefined : "15px",
});

const description = (isMobile: boolean) => ({
  color: "white",
  fontWeight: "400",
  fontSize: isMobile ? "13px" : "18px",
});

const circleImgStyle = {
  width: "40px",
  height: "40px",
};
