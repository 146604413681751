/** @jsxImportSource @emotion/react */
import { RecycleIcons } from "../../../../assets/icon/icon";
import useBasePadding from "../../../../hooks/useBasePadding";
import useResponsive from "../../../../hooks/useResponsive";
import { DELIVERY_METHOD_STRUCTURE } from "./structure";

const texts = {
  content: `상담을 통해 고객사의 니즈에 맞춘 제품군을 선택하고 사용 목적에 맞는 계약 방식을 통해 WIMPLE과 함께하세요.`,
  contentMobile: `상담을 통해 고객사의 니즈에 맞춘
  제품군을 선택하고 사용 목적에 맞는
  계약 방식을 통해 WIMPLE과 함께하세요.`,
};

export default function DeliveryMethod() {
  const { GOLBAL_PADDING } = useBasePadding();
  const { isPc } = useResponsive();
  return (
    <div css={wrapper(GOLBAL_PADDING, !isPc)}>
      <div css={textBox}>
        <p css={title}>납품방식</p>
        {isPc ? <pre css={description}>{texts.content}</pre> : <pre css={description}>{texts.contentMobile}</pre>}
      </div>
      <div css={contentContainer(isPc)}>
        {DELIVERY_METHOD_STRUCTURE.map((item, i) => {
          return (
            <div
              key={item.title}
              css={{
                display: "flex",
                alignItems: "center",
                flexDirection: isPc ? "row" : "column",
              }}
            >
              <div css={content}>
                <div>
                  <img css={{ marginBottom: isPc ? "50px" : "20px" }} src={item.icon} alt={String(i)} />
                </div>
                <p css={itemTitle}>{item.title}</p>
                <pre css={itemDescription}>{item.description}</pre>
              </div>
              {i !== 3 && (
                <div>
                  <img
                    css={{
                      margin: "38px 15px",
                      transform: isPc ? `translateY(-1px) rotate(0deg)` : `translateY(0) rotate(90deg)`,
                    }}
                    src={RecycleIcons.arrow}
                    alt="arrow"
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

const wrapper = (padding: string, isMobile: boolean) => ({
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
  paddingLeft: padding,
  paddingRight: padding,
  background: "black",
  marginTop: isMobile ? "100px" : "200px",
});

const textBox = {
  textAlign: "center" as const,
};

const title = {
  color: "white",
  fontSize: "32px",
  fontWeight: "600",
  marginBottom: "15px",
};

const description = {
  color: "white",
  fontSize: "18px",
  fontWeight: "500",
  lineHeight: "27px",
};

const contentContainer = (isPc: boolean) => ({
  display: "flex",
  marginTop: "100px",
  flexDirection: isPc ? ("row" as const) : ("column" as const),
});

const content = {
  display: "flex",
  flexDirection: "column" as const,
  justifyContent: "center",
  alignItems: "center",
  width: "275px",
  maxHeight: "233px",
};

const itemTitle = {
  color: "white",
  fontSize: "20px",
  fontWeight: "700",
  marginBottom: "15px",
};

const itemDescription = {
  color: "white",
  fontSize: "15px",
  fontWeight: "400",
  lineHeight: "22.5px",
  textAlign: "center" as const,
};
