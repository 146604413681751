/** @jsxImportSource @emotion/react */
import { MedicalImage } from "../../../../assets/img/img";
import useBasePadding from "../../../../hooks/useBasePadding";
import useResponsive from "../../../../hooks/useResponsive";

const texts = {
  content1: `CAPTOS 플랫폼 사용만으로 캡슐내시경 영상 분석이 가능하여 판독 시간이 단축됩니다.`,
  content1Mobile: `CAPTOS 플랫폼 사용만으로 캡슐내시경
영상 분석이 가능하여 판독 시간이 단축됩니다.`,
  content2: `의료진 판독 결과와 CAPTOS 플랫폼 분석 결과 비교 시 유사한 정확도를 보입니다.`,
  content2Mobile: `의료진 판독 결과와 CAPTOS 플랫폼 분석 결과 비교 시
유사한 정확도를 보입니다.`,
};
export default function Section4() {
  const { GOLBAL_PADDING } = useBasePadding();
  const { isPc } = useResponsive();
  return (
    <div css={wrapper(GOLBAL_PADDING, !isPc)}>
      <div css={layout(!isPc)}>
        <div>
          <p css={title(!isPc)}>
            내시경 전문의 판독 시간 <span css={stress}>90%</span> 단축
          </p>
          {!isPc ? (
            <pre css={description(!isPc)}>{texts.content1Mobile}</pre>
          ) : (
            <pre css={description(!isPc)}>{texts.content1}</pre>
          )}
          <img css={imgStyle(!isPc)} src={MedicalImage.ai.Graph1} alt="graph1" />
        </div>
        <div css={content}>
          <p css={title(!isPc)}>
            내시경 전문의 판독 결과 <span css={stress}>95%</span> 일치
          </p>
          {!isPc ? (
            <pre css={description(!isPc)}>{texts.content2Mobile}</pre>
          ) : (
            <pre css={description(!isPc)}>{texts.content2}</pre>
          )}
          <img css={imgStyle(!isPc)} src={MedicalImage.ai.Graph2} alt="graph2" />
        </div>
      </div>
    </div>
  );
}

const wrapper = (padding: string, isMobile: boolean) => ({
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
  paddingLeft: padding,
  paddingRight: padding,
  background: "black",
  marginTop: isMobile ? "100px" : "200px",
});

const layout = (isMobile: boolean) => ({
  display: "grid",
  gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
  gridTemplateRows: isMobile ? "1fr 1fr" : "1fr",
  gap: "154px",
  maxWidth: isMobile ? "285px" : "",
});

const content = {};

const title = (isMobile: boolean) => ({
  color: "white",
  fontSize: isMobile ? "20px" : "30px",
  fontWeight: "600",
  marginBottom: "15px",
});

const description = (isMobile: boolean) => ({
  color: "white",
  fontSize: isMobile ? "14px" : "18px",
  fontWeight: "500",
  marginBottom: isMobile ? "24px" : "100px",
});

const stress = {
  background: "linear-gradient(90deg, #6DDCFF 0%, #7F60F9 100%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
};

const imgStyle = (isMobile: boolean) => ({
  width: isMobile ? "285px" : "",
});
