/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import checkedBox from "../../assets/img/Contact/check.svg";
import unCheckedBox from "../../assets/img/Contact/unCheck.svg";
import Modal from "./Modal";
import useResponsive from "../../hooks/useResponsive";
import emailjs from "emailjs-com";

export default function Contact() {
  const { isMobile } = useResponsive();
  const [formData, setFormData] = useState({
    name: "",
    organization: "",
    position: "",
    country: "",
    contact: "",
    email: "",
    inquiry: "",
    selectedRadio: "",
  });
  const [checked, setChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSelectedButton, setIsSelectedButton] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    setIsSelectedButton(false);
  }, []);

  const handleCheckboxClick = () => {
    setChecked(!checked);
  };

  const handleRadioClick = (value: string) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedRadio: value,
    }));
  };

  const handleSpanClick = () => {
    setIsModalOpen(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setIsSelectedButton(true);
    e.preventDefault();

    if (!checked) {
      alert("개인정보 수집 및 이용약관에 동의해주세요.");
      setIsSelectedButton(false);
      return;
    }
    if (!formData.name) {
      alert("성함을 작성해 주세요.");
      setIsSelectedButton(false);
      return;
    }
    if (!formData.organization) {
      alert("기관/회사명을 작성해 주세요.");
      setIsSelectedButton(false);
      return;
    }
    if (!formData.position) {
      alert("직함을 작성해 주세요.");
      setIsSelectedButton(false);
      return;
    }
    if (!formData.country) {
      alert("국가를 작성해 주세요.");
      setIsSelectedButton(false);
      return;
    }
    if (!formData.contact) {
      alert("연락처를 작성해 주세요.");
      setIsSelectedButton(false);
      return;
    }
    if (formData.contact && !/^\d+$/.test(formData.contact)) {
      alert("연락처를 확인해 주세요.");
      setIsSelectedButton(false);
      return;
    }
    if (!formData.email) {
      alert("이메일을 작성해 주세요.");
      setIsSelectedButton(false);
      return;
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailPattern.test(formData.email)) {
      alert("이메일을 확인해 주세요.");
      setIsSelectedButton(false);
      return;
    }
    if (!formData.selectedRadio) {
      alert("문의 유형을 선택해 주세요.");
      setIsSelectedButton(false);
      return;
    }
    if (!formData.inquiry) {
      alert("문의 사항을 작성해 주세요.");
      setIsSelectedButton(false);
      return;
    }

    emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID!,
        process.env.REACT_APP_TEMPLATE_ID!,
        formData,
        process.env.REACT_APP_PUBLIC_KEY!
      )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        alert("이메일이 성공적으로 전송되었습니다!");
        window.location.reload();
      })
      .catch((error) => {
        console.error("FAILED...", error);
        alert("이메일 전송에 실패했습니다. 다시 시도해 주세요.");
        setIsSelectedButton(false);
      });
  };

  return (
    <form css={isContactMobileStyle(isMobile)} onSubmit={handleSubmit}>
      <div>
        <p css={titleText}>Contact</p>
      </div>
      <div css={InputContainer}>
        <input
          type="text"
          name="name"
          placeholder="성함*"
          css={inputStyle}
          value={formData.name}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="organization"
          placeholder="기관/회사명*"
          css={inputStyle}
          value={formData.organization}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="position"
          placeholder="직함*"
          css={inputStyle}
          value={formData.position}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="country"
          placeholder="국가*"
          css={inputStyle}
          value={formData.country}
          onChange={handleInputChange}
        />
        <input
          type="tel"
          name="contact"
          placeholder="연락처*"
          css={inputStyle}
          value={formData.contact}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="email"
          placeholder="이메일*"
          css={inputStyle}
          value={formData.email}
          onChange={handleInputChange}
        />
        <div css={RadioButtonContainer}>
          <p css={textStyle}>문의 유형*</p>
          <div css={RadioButtonContainer}>
            {["데이터 바우처", "AI Data / Algorithm", "Recycle", "Medical", "기타 문의"].map((type) => (
              <label key={type} css={radioButtonLabel} onClick={() => handleRadioClick(type)}>
                <img
                  src={formData.selectedRadio === type ? checkedBox : unCheckedBox}
                  css={radioButtonStyle}
                  alt="radio button"
                />
                {type === "데이터 바우처" && "데이터 바우처"}
                {type === "AI Data / Algorithm" && "AI Data / Algorithm"}
                {type === "Recycle" && "Recycle"}
                {type === "Medical" && "Medical"}
                {type === "기타 문의" && "기타 문의"}
              </label>
            ))}
          </div>
        </div>
        <textarea
          name="inquiry"
          placeholder="문의 사항을 작성해 주세요."
          css={inquiryInputStyle}
          value={formData.inquiry}
          onChange={handleInputChange}
        />
      </div>
      <div css={agreementStyle}>
        <img
          src={checked ? checkedBox : unCheckedBox}
          css={radioButtonStyle}
          alt="checkbox"
          onClick={handleCheckboxClick}
        />
        <p css={textStyle}>
          <span css={agreementSpanStyle} onClick={handleSpanClick}>
            개인정보 수집 및 이용약관
          </span>{" "}
          확인 및 동의*
        </p>
      </div>
      <div css={submitButtonContainer}>
        <input type="submit" value="제출하기" css={submitButtonStyle(isSelectedButton)} disabled={isSelectedButton} />
      </div>
      {isModalOpen && <Modal setIsModalOpen={setIsModalOpen} />}
    </form>
  );
}

const ContactStyle = {
  display: "flex",
  flexDirection: "column" as const,
  width: "100vw",
  height: "100%",
  padding: "126px 16.67% 100px",
};

const ContactMobileStyle = {
  ...ContactStyle,
  padding: "126px 7% 100px",
};

const isContactMobileStyle = (isMobile: boolean) => (isMobile ? ContactMobileStyle : ContactStyle);

const titleText = {
  fontSize: "32px",
  fontWeight: 600,
  lineHeight: "38.19px",
  textAlign: "left" as const,
  background: "linear-gradient(90deg, #6DDCFF 0%, #7F60F9 100%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  color: "transparent",
  paddingBottom: "136px",
  display: "inline-block",
};

const textStyle = {
  fontSize: "18px",
  fontWeight: 400,
  lineHeight: "27px",
  textAlign: "left" as const,
  color: "white",
};

const InputContainer = {
  display: "flex",
  flexDirection: "column" as const,
  gap: "20px",
  ...textStyle,
};

const inputStyle = {
  background: "black",
  padding: "10px",
  borderRadius: "10px",
  border: "1px solid #D9D9D9",
  width: "100%",
  height: "50px",
  boxSizing: "border-box" as const,
  paddingLeft: "20px",
  "::placeholder": {
    ...textStyle,
  },
};

const RadioButtonContainer = {
  width: "fit-content",
  display: "flex",
  flexDirection: "column" as const,
  gap: "20px",
};

const radioButtonLabel = {
  ...textStyle,
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
};

const radioButtonStyle = {
  marginRight: "10px",
  width: "20px",
  height: "20px",
  cursor: "pointer",
};

const inquiryInputStyle = {
  ...inputStyle,
  height: "247px",
  paddingTop: "20px",
  paddingLeft: "20px",
  "::placeholder": {
    ...textStyle,
  },
};

const agreementStyle = {
  ...textStyle,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "50px 0 50px",
};

const agreementSpanStyle = {
  ...textStyle,
  color: "#7F60F9",
  cursor: "pointer",
  ":hover": {
    textDecoration: "underline",
  },
};

const submitButtonContainer = {
  display: "flex",
  justifyContent: "center" as const,
};

const submitButtonStyle = (isSelectedButton: boolean) => ({
  width: "300px",
  height: "50px",
  borderRadius: "10px",
  border: "none",
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "16.71px",
  textAlign: "center" as const,
  color: "white",
  cursor: "pointer",
  background: isSelectedButton ? "linear-gradient(90deg, #6DDCFF 0%, #7F60F9 100%)" : "#3d3d3d",
  ":hover": {
    background: "linear-gradient(90deg, #6DDCFF 0%, #7F60F9 100%)",
  },
});
