/** @jsxImportSource @emotion/react */
import React from "react";
import DataVaucher from "./template/DataVoucher";
import FinalContent from "../../component/final/finalContent";
const AiDataMain = React.lazy(() => import("./template/AiDataMain"));
const DataProcessingMethod = React.lazy(() => import("./template/DataProcessingMethod"));
const Process = React.lazy(() => import("./template/Process"));
const AiService = React.lazy(() => import("./template/Service"));
const DataProcessingProject = React.lazy(() => import("./template/DataProcessingProject"));
export default function AiData() {
  return (
    <div css={wrapper}>
      <AiDataMain />
      <AiService />
      <DataProcessingProject />
      <Process />
      <DataProcessingMethod />
      <DataVaucher />
      <FinalContent page="AIData" />
    </div>
  );
}

const wrapper = {
  display: "flex",
  flexDirection: "column" as const,
  width: "100%",
};
