/** @jsxImportSource @emotion/react */
import { RecycleIcons } from "../../../../assets/icon/icon";
import { RecycleImage } from "../../../../assets/img/img";
import useBasePadding from "../../../../hooks/useBasePadding";
import useResponsive from "../../../../hooks/useResponsive";

const texts = {
  subtitle: `재활용품 수집 플랫폼, WASTE + SIMPLE = WIMPLE`,
  subtitleMobile: `재활용품 수집 플랫폼,
WASTE + SIMPLE = WIMPLE`,
  contentPc: `우리나라는 OECD 국가 중 분리배출 실행률은 약 60%로 세계 2위를 차지하나, 우리가 분리배출하는 재활용품은 운송 과정 중 다시 뒤섞이고 
선별과정에서 사람의 눈과 손에 의존하여 60% 중 40%로 가량은 에너지 회수 명목으로 소각되거나 매립됩니다.
사람에게 의존하는 현재 선별 프로세스는 일관적이고 정확한 분리수거/선별이 어려워 매립/소각되는 재활용 가능 품목의 양을 증가시킵니다.`,
  contentMobile: `우리나라는 OECD 국가 중 분리배출 실행률은
약 60%로 세계 2위를 차지하나,
우리가 분리배출하는 재활용품은 운송 과정 중 다시 뒤섞이고 
선별과정에서 사람의 눈과 손에 의존하여
60% 중 40%로 가량은 에너지 회수 명목으로
소각되거나 매립됩니다.
사람에게 의존하는 현재 선별 프로세스는 일관적이고
정확한 분리수거/선별이 어려워 매립/소각되는
재활용 가능 품목의 양을 증가시킵니다.`,
  content2Mobile: `㈜서르는 재활용률을 높이기 위하여
폐기물을 버리는 초기 단계에서부터 간편하게 폐기물을
투입만 하면 올바른 기준으로 분리수거를 대신함으로써, 
고품질 자원 수집을 가능하게 하여 양질의 재활용품 선별을 위한
후공정을 줄일 수 있는 플랫폼 WIMPLE을 구축하였습니다.
WIMPLE은 인공지능 WEE가 탑재되어 있어 사용자가 수집하기
원하는 재활용 품목을 설정한 뒤, 폐기물을 투입만 하면 
스스로 투입된 폐기물을 재활용 가능 여부와 품목을
인식하고 분류합니다.`,
  content2Pc: `㈜서르는 재활용률을 높이기 위하여 폐기물을 버리는 초기 단계에서부터 간편하게 폐기물을 투입만 하면 올바른 기준으로 분리수거를 대신함으로써, 
고품질 자원 수집을 가능하게 하여 양질의 재활용품 선별을 위한 후공정을 줄일 수 있는 플랫폼 WIMPLE을 구축하였습니다.
WIMPLE은 인공지능 WEE가 탑재되어 있어 사용자가 수집하기 원하는 재활용 품목을 설정한 뒤, 폐기물을 투입만 하면 
스스로 투입된 폐기물을 재활용 가능 여부와 품목을 인식하고 분류합니다.`,
};

export default function WimpleDescription() {
  const { GOLBAL_PADDING } = useBasePadding();
  const { isPc, isMobile } = useResponsive();
  return (
    <div css={wrapper(GOLBAL_PADDING)}>
      <img
        css={{ marginBottom: "15px" }}
        src={RecycleIcons.wimpleLogo}
        alt="wimpleLogo"
      />
      {!isPc ? (
        <pre css={discriptionStyle(!isPc)}>{texts.subtitleMobile}</pre>
      ) : (
        <p css={discriptionStyle(!isPc)}>{texts.subtitle}</p>
      )}
      <div css={{ marginBottom: "15px", marginTop: "15px" }}>
        {isPc ? (
          <>
            <pre css={discriptionStyle(!isPc)}>{texts.contentPc}</pre>
          </>
        ) : (
          <>
            <pre css={discriptionStyle(!isPc)}>{texts.contentMobile}</pre>
          </>
        )}
      </div>
      <div css={imgContent(!isPc)}>
        <img
          css={{ objectFit: "cover", width: isMobile ? "365px" : "100%" }}
          src={RecycleImage.WimpleImgProcess.processImage}
          alt="1"
        />
      </div>
      {isPc ? (
        <div>
          <pre css={discriptionStyle(!isPc)}>{texts.content2Pc}</pre>
        </div>
      ) : (
        <div>
          <pre css={discriptionStyle(!isPc)}>{texts.content2Mobile}</pre>
        </div>
      )}

      <div css={imgContent(!isPc)}>
        <img
          css={{ objectFit: "cover", width: isMobile ? "355px" : "100%" }}
          src={RecycleIcons.processIcon}
          alt="1"
        />
      </div>
    </div>
  );
}

const wrapper = (padding: string) => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column" as const,
  alignItems: "center",
  minHeight: "100vh",
  paddingLeft: padding,
  paddingRight: padding,
  background: "black",
  marginTop: "200px",
});

const discriptionStyle = (isMobile: boolean) => ({
  color: "white",
  fontWeight: "500",
  fontSize: isMobile ? "14px" : "18px",
  textAlign: "center" as const,
  lineHeight: isMobile ? "22px" : "27px",
});

const imgContent = (isMobile: boolean) => ({
  position: "relative" as const,
  display: "flex",
  flexDirection: "row" as const,
  alignItems: "center",
  marginTop: isMobile ? "24px" : "100px",
  marginBottom: isMobile ? "24px" : "100px",
});
