import { RecycleIcons } from "../../../../assets/icon/icon";

export const DELIVERY_METHOD_STRUCTURE = [
  {
    icon: RecycleIcons.DeliveryMehod.DeliveryMethod1,
    title: "고객 상담 진행",
    description: `고객 상담을 통해 WIMPLE 제품 중
고객사의 구매 및 대여 목적과 
설치 환경에 적합한 제품을 논의합니다.`,
  },
  {
    icon: RecycleIcons.DeliveryMehod.DeliveryMethod2,
    title: "주문 접수",
    description: `고객으로 부터 협의한 내용에 따른
계약서를 작성하고 주문 접수에 따라
발주 진행합니다.`,
  },
  {
    icon: RecycleIcons.DeliveryMehod.DeliveryMethod3,
    title: "제품 출하 / 배송",
    description: `제품 생산 후 품질 검사 진행 후
안전 포장하여 계약한 날짜, 장소에
WIMPLE을 설치합니다.`,
  },
  {
    icon: RecycleIcons.DeliveryMehod.DeliveryMethod4,
    title: "메뉴얼 전달",
    description: `납품 확인서 및 사용자 메뉴얼을 
제공합니다. 메뉴얼 내 QR코드를 통해 
제품 상세 내용 확인이 가능합니다.`,
  },
];
