/** @jsxImportSource @emotion/react */
import useResponsive from "../../../../../hooks/useResponsive";

export default function Year2024() {
  const { isMobile } = useResponsive();
  return (
    <div css={yearStyle}>
      <div css={textBox(isMobile)}>
        <p css={numberText(isMobile)}>09</p>
        <p css={text(isMobile)}>AI 분리수거 교육시스템 Wimple EDU 출시</p>
      </div>
      <div css={textBox(isMobile)}>
        <p css={numberText(isMobile)}>08</p>
        <div css={Texts}>
          <p css={text(isMobile)}>캡슐내시경 진단보조시스템 양산부산대학교 납품</p>
          <p css={text(isMobile)}>벤처나라 입점 기관 선정</p>
          <p css={text(isMobile)}>공공용 분리수거기 Wimple Bin M 출시</p>
        </div>
      </div>
      <div css={textBox(isMobile)}>
        <p css={numberText(isMobile)}>07</p>
        <p css={text(isMobile)}>IITP, ICT·SW R&D 우수성과 글로벌 스케일업 사업 참여</p>
      </div>
      <div css={textBox(isMobile)}>
        <p css={numberText(isMobile)}>06</p>
        <div css={Texts}>
          <p css={text(isMobile)}>부산대학교 의생명특화총괄본부, 의료기기 인증 지원사업 선정</p>
          <p css={text(isMobile)}>NIA, 헬스케어 분야 인공지능 학습용 데이터 구축 사업 참여</p>
          <p css={text(isMobile)}>기술보증기금, Pre-A 투자유치 완료</p>
        </div>
      </div>
      <div css={textBox(isMobile)}>
        <p css={numberText(isMobile)}>05</p>
        <div css={Texts}>
          <p css={text(isMobile)}>재단법인 대구테크노파크, 의료기기 임상 지원사업 선정</p>
          <p css={text(isMobile)}>중소벤처기업부, 창업성장기술개발사업(디딤돌) 선정</p>
          <p css={text(isMobile)}>한국생산성학회, 2024 제31회 생산성 CEO 대상 수상</p>
        </div>
      </div>
      <div css={textBox(isMobile)}>
        <p css={numberText(isMobile)}>04</p>
        <div css={Texts}>
          <p css={text(isMobile)}>중소벤처기업부, 초격차 스타트업 1000+ 프로젝트 선정</p>
          <p css={colorText(isMobile)}>주식회사 캡토스 → 주식회사 서르 상호변경</p>
        </div>
      </div>
      <div css={textBox(isMobile)}>
        <p css={numberText(isMobile)}>03</p>
        <p css={colorText(isMobile)}>주식회사 서르 합병</p>
      </div>
    </div>
  );
}

const yearStyle = {
  display: "flex",
  flexDirection: "column" as const,
  gap: "10px",
};

const textBox = (isMobile: boolean) => ({
  display: "flex",
  flexDirection: "row" as const,
  gap: isMobile ? "15px" : "30px",
});

const Texts = {
  display: "flex",
  flexDirection: "column" as const,
  gap: "10px",
};

const numberText = (isMobile: boolean) => ({
  fontSize: isMobile ? "14px" : "20px",
  fontWeight: 700,
  lineHeight: "27px",
  textAlign: "center" as const,
  color: "white",
});

const text = (isMobile: boolean) => ({
  fontSize: isMobile ? "14px" : "18px",
  fontWeight: 400,
  lineHeight: "27px",
  textAlign: "left" as const,
  color: "white",
});

const colorText = (isMobile: boolean) => ({
  ...text(isMobile),
  background: "linear-gradient(90deg, #6DDCFF 0%, #7F60F9 100%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
});
