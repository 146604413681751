export const SECTION6_THESIS_STRUCTURE = [
  {
    index: "01",
    years: "2023",
    title:
      "Semantic Map Guided Synthesis of Wireless Capsule Endoscopy Images using Diffusion Models",
    link: "https://openreview.net/pdf?id=s5nWttQIib",
  },
  {
    index: "02",
    years: "2023",
    title:
      "Redesigning Embedding Layers for Queries, Keys, and Values in Cross-Covariance Image Transformers",
    link: "https://www.researchgate.net/publication/370172848_Redesigning_Embedding_Layers_for_Queries_Keys_and_Values_in_Cross-Covariance_Image_Transformers",
  },
  {
    index: "03",
    years: "2023",
    title:
      "Clean mucosal area detection of gastroenterologists versus artificial intelligence in small bowel capsule endoscopy",
    link: "https://journals.lww.com/md-journal/fulltext/2023/02100/clean_mucosal_area_detection_of.15.aspx",
  },
  {
    index: "04",
    years: "2023",
    title:
      "Establishing Semantic Segmentation Dataset for AI-Based Quantification of Clean Mucosa in Capsule Endoscopy",
    link: "https://www.giejournal.org/article/S0016-5107(22)00960-9/abstract",
  },
  {
    index: "05",
    years: "2022",
    title:
      "Semantic Segmentation Dataset for AI-Based Quantification of Clean Mucosa in Capsule Endoscopy",
    link: "https://www.mdpi.com/1648-9144/58/3/397",
  },
  {
    index: "06",
    years: "2022",
    title:
      "Extending Contrastive Learning to Unsupervised Redundancy Identification",
    link: "https://www.mdpi.com/2076-3417/12/4/2201",
  },
  {
    index: "07",
    years: "2022",
    title:
      "Training data reduction for deep learning-based image classfication using random sample consunsus",
    link: "https://research.knu.ac.kr/en/publications/training-data-reduction-for-deep-learning-based-image-classificat",
  },
];
