/** @jsxImportSource @emotion/react */
import { RecycleIcons } from "../../../../assets/icon/icon";
import useBasePadding from "../../../../hooks/useBasePadding";
import useResponsive from "../../../../hooks/useResponsive";
import Content from "./modules/Content";
import { KEY_POINT_STRUCTURE } from "./structure";

const texts = {
  content: `WIMPLE은 기존의 인공지능 재활용품 선별기와는 차별성을 가집니다.`,
  contentMobile: `WIMPLE은 기존의 인공지능 재활용품
선별기와는 차별성을 가집니다.`,
};
export default function KeyPoint() {
  const { GOLBAL_PADDING } = useBasePadding();
  const { isPc } = useResponsive();
  return (
    <div css={wrapper(GOLBAL_PADDING, !isPc)}>
      <div css={{ textAlign: "center" }}>
        <p css={title}>
          <img src={RecycleIcons.KeyPoint.WimpleLogo} alt="wimpleLogo" /> KEY POINT
        </p>
        {!isPc ? <pre css={description}>{texts.contentMobile}</pre> : <pre css={description}>{texts.content}</pre>}
      </div>
      {!isPc ? (
        <div css={contentContainer(!isPc)}>
          {KEY_POINT_STRUCTURE.map((item) => {
            return (
              <Content
                key={item.title}
                img={item.img}
                titleIcon={item.titleIcon}
                title={item.title}
                description={item.descriptionMobile}
              />
            );
          })}
        </div>
      ) : (
        <div css={contentContainer(!isPc)}>
          {KEY_POINT_STRUCTURE.map((item) => {
            return (
              <Content
                key={item.title}
                img={item.img}
                titleIcon={item.titleIcon}
                title={item.title}
                description={item.description}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

const wrapper = (padding: string, isMobile: boolean) => ({
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
  minHeight: "100vh",
  paddingLeft: padding,
  paddingRight: padding,
  background: "black",
  marginTop: isMobile ? "100px" : "400px",
});

const title = {
  color: "white",
  fontSize: "32px",
  fontWeight: "600",
};
const description = {
  marginTop: "15px",
  color: "white",
  fontSize: "18px",
  fontWeight: "500",
  marginBottom: "100px",
};

const contentContainer = (isMobile: boolean) => ({
  display: "flex",
  flexDirection: "column" as const,
  gap: isMobile ? "100px" : "100px",
});
