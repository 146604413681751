/** @jsxImportSource @emotion/react */
import useResponsive from "../../../../../hooks/useResponsive";

export default function Year2022() {
  const { isMobile } = useResponsive();
  return (
    <div css={yearStyle}>
      <div css={textBox(isMobile)}>
        <p css={numberText(isMobile)}>12</p>
        <div css={Texts}>
          <p css={text(isMobile)}>DX Challenge App개발 우수상</p>
          <p css={text(isMobile)}>교육부장관, 우수산학협력활동 기여 표창 수여</p>
          <p css={text(isMobile)}>데이터바우처, 우수사례 데이터가공 과학기술정보통신부장관상 수상</p>
        </div>
      </div>
      <div css={textBox(isMobile)}>
        <p css={numberText(isMobile)}>11</p>
        <p css={text(isMobile)}>부산지방중소벤처기업청, 2022년 웰컴투 팁스 in 부산 최우수상 수상</p>
      </div>
      <div css={textBox(isMobile)}>
        <p css={numberText(isMobile)}>09</p>
        <p css={text(isMobile)}>
          부산창조경제혁신센터, B.스타트업오픈이노베이션챌린지선정 (롯데글로벌로지스와 PoC진행)
        </p>
      </div>
      <div css={textBox(isMobile)}>
        <p css={numberText(isMobile)}>07</p>
        <div css={Texts}>
          <p css={text(isMobile)}>인공지능 온라인 경진대회 이미지분야 2등 수상</p>
          <p css={text(isMobile)}>NIA, 환경분야 인공지능 학습용 데이터 구축 사업 참여 (재활용 폐기물) *우수사례 선정</p>
          <p css={text(isMobile)}>
            인텔, 글로벌 2022 IRTI awards 수상 – 크론병의 진단을 위한 내시경 판독 AI 모델 솔루션
          </p>
        </div>
      </div>
      <div css={textBox(isMobile)}>
        <p css={numberText(isMobile)}>06</p>
        <p css={text(isMobile)}>IITP, ICT기반혁신제품·서비스 아이디어 BizProject 공모전 은상</p>
      </div>
      <div css={textBox(isMobile)}>
        <p css={numberText(isMobile)}>05</p>
        <div css={Texts}>
          <p css={text(isMobile)}>동의대, 인공지능 전문인력 양성과정 일자리 창출 MOU 협약</p>
          <p css={text(isMobile)}>(사)대한임베디드공학회, ISET2023 우수논문상 수여</p>
        </div>
      </div>
      <div css={textBox(isMobile)}>
        <p css={numberText(isMobile)}>04</p>
        <div css={Texts}>
          <p css={text(isMobile)}>NIA, 헬스케어 분야 인공지능 학습용 데이터 구축 사업 참여</p>
          <p css={text(isMobile)}>NIPA, AI바우처 공급기업 선정</p>
          <p css={text(isMobile)}>IITP, ICT기반혁신제품·서비스 개발 지원 사업 참여(R&D)</p>
        </div>
      </div>
      <div css={textBox(isMobile)}>
        <p css={numberText(isMobile)}>03</p>
        <div css={Texts}>
          <p css={text(isMobile)}>부산소프트웨어마이스터고, 산학협력 협약</p>
          <p css={text(isMobile)}>보건산업진흥원 카자흐스탄 캡슐내시경 진단보조시스템 시범사업 선정</p>
        </div>
      </div>
      <div css={textBox(isMobile)}>
        <p css={numberText(isMobile)}>01</p>
        <p css={colorText(isMobile)}>주식회사 캡토스 설립</p>
      </div>
    </div>
  );
}

const yearStyle = {
  display: "flex",
  flexDirection: "column" as const,
  gap: "10px",
};

const textBox = (isMobile: boolean) => ({
  display: "flex",
  flexDirection: "row" as const,
  gap: isMobile ? "15px" : "30px",
});

const Texts = {
  display: "flex",
  flexDirection: "column" as const,
  gap: "10px",
};

const numberText = (isMobile: boolean) => ({
  fontSize: isMobile ? "14px" : "20px",
  fontWeight: 700,
  lineHeight: "27px",
  textAlign: "center" as const,
  color: "white",
});

const text = (isMobile: boolean) => ({
  fontSize: isMobile ? "14px" : "18px",
  fontWeight: 400,
  lineHeight: "27px",
  textAlign: "left" as const,
  color: "white",
});

const colorText = (isMobile: boolean) => ({
  ...text(isMobile),
  background: "linear-gradient(90deg, #6DDCFF 0%, #7F60F9 100%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
});
