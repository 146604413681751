/** @jsxImportSource @emotion/react */
import close from "../../../assets/img/Contact/close.svg";
import useResponsive from "../../../hooks/useResponsive";

interface ModalProps {
  setIsModalOpen: (value: boolean) => void;
}

export default function Modal({ setIsModalOpen }: ModalProps) {
  const { isTablet, isMobile } = useResponsive();
  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  return (
    <div css={wrapper}>
      <div css={[isMobileModalOverStyle(isMobile), scrollbarStyle]}>
        <div css={isMobileModalContainer(isMobile)}>
          <div css={isMobileModalHeader(isMobile)}>
            <p css={headerText}>개인정보 수집 및 이용약관</p>
            <img
              src={close}
              alt="close"
              css={closeImg}
              onClick={handleModalClose}
            />
          </div>
          <div css={ModalContent(isMobile)}>
            <div>
              <p>01 개인정보 수집 및 이용 목적</p>
              <ul css={listStyle}>
                <li>문의 사항 제출자 식별</li>
                <li>문의 사항 답변</li>
              </ul>
            </div>
            <div>
              <p>02 수집하는 개인정보 항목</p>
              <ul css={listStyle}>
                <li>
                  이름, 기관/회사명, 직함, 국가, 연락처, 이메일 주소, 문의사항
                </li>
              </ul>
            </div>
            <div>
              <p>03 개인정보 보유 및 이용 기간</p>
              <ul css={listStyle}>
                <li>
                  개인정보 수집 및 이용에 대한 동의 철회 또는 수집 및 이용 목적
                  달성 시
                </li>
              </ul>
            </div>
            <div>
              <p>04 동의를 거부할 권리 및 동의 거부에 따른 제약 사항</p>
              <ul css={listStyle}>
                <li>
                  정보주체는 개인정보 수집 및 이용을 거부할 권리가 있으나 동의
                  거부 시 문의사항 제출이 불가함
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const wrapper = {
  position: "fixed" as const,
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center" as const,
};

const modalOverStyle = {
  boxSizing: "border-box" as const,
  width: "500px",
  height: "437px",
  background: "white",
  borderRadius: "10px",
  display: "flex",
  justifyContent: "center",
};

const MobileModalOverStyle = {
  ...modalOverStyle,
  width: "350px",
  height: "350px",
  overflow: "scroll",
};

const scrollbarStyle = {
  "&::-webkit-scrollbar": {
    display: "none",
  },
};

const isMobileModalOverStyle = (isMobile: boolean) =>
  isMobile ? MobileModalOverStyle : modalOverStyle;

const ModalContainer = {
  width: "100%",
  height: "100%",
  padding: "25px 50px",
};

const MobileModalContainer = {
  ...ModalContainer,
  padding: "30px",
};

const isMobileModalContainer = (isMobile: boolean) =>
  isMobile ? MobileModalContainer : ModalContainer;

const ModalHeader = {
  display: "flex",
  gap: "78px",
  paddingBottom: "21px",
  paddingLeft: "73px",
  borderBottom: "1px solid #D9D9D9",
};

const mobileModalHeader = {
  ...ModalHeader,
  gap: "25px",
  paddingLeft: "25px",
};

const isMobileModalHeader = (isMobile: boolean) =>
  isMobile ? mobileModalHeader : ModalHeader;

const headerText = {
  fontSize: "20px",
  fontWeight: 700,
  lineHeight: "23.87px",
  textAlign: "left" as const,
};

const closeImg = {
  width: "20px",
  height: "20px",
  cursor: "pointer",
};

const textStyle = {
  fontSize: "15px",
  fontWeight: 400,
  lineHeight: "22.5px",
  textAlign: "left" as const,
};

const mobileTextStyle = {
  ...textStyle,
  fontSize: "13px",
  lineHeight: "18px",
};

const ModalContent = (isMobile: boolean) => ({
  paddingTop: "20px",
  ...(isMobile ? mobileTextStyle : textStyle),
  display: "flex",
  flexDirection: "column" as const,
  gap: "20px",
});

const listStyle = {
  paddingLeft: "20px",
};
