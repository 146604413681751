import { RecycleIcons } from "../../../../assets/icon/icon";
import { RecycleImage } from "../../../../assets/img/img";

export const KEY_POINT_STRUCTURE = [
  {
    img: RecycleImage.KeyPoint.Point1,
    titleIcon: RecycleIcons.KeyPoint.Point1,
    title: "자동 선별 인공지능 & 하드웨어",
    description: `투입구에 폐기물을 투입만 하면 인공지능 WEE가 98%의 정확도로 정확하고 빠르게
폐기물 품목을 인식하고 하드웨어가 인식 결과 값에 따라 분류합니다.`,
    descriptionMobile: `투입구에 폐기물을 투입만 하면 인공지능 WEE가
98%의 정확도로 정확하고 빠르게
폐기물 품목을 인식하고 하드웨어가 인식 결과 값에 따라
분류합니다.`,
  },
  {
    img: RecycleImage.KeyPoint.Point2,
    titleIcon: RecycleIcons.KeyPoint.Point2,
    title: "8종의 분류 품목 커스텀",
    description: `각 적재함 마다 종이, 종이컵/종이팩, 플라스틱, 페트, 캔, 유리, 비닐, 일반쓰레기 중 수집할 품목을
관리자 페이지나 서르 고객센터를 통에서 설정하면 WIMPLE이 설정 폐기물을 자동으로 분류합니다.`,
    descriptionMobile: `각 적재함 마다 종이, 종이컵/종이팩, 플라스틱,
  페트, 캔, 유리, 비닐, 일반쓰레기 중 수집할 품목을
관리자 페이지나 서르 고객센터를 통에서 설정하면
WIMPLE이 설정 폐기물을 자동으로 분류합니다.`,
  },
  {
    img: RecycleImage.KeyPoint.Point3,
    titleIcon: RecycleIcons.KeyPoint.Point3,
    title: "제품 사용 통계 정보 제공",
    description: `기기 사용에 따라 분류된 폐기물 품목 및 양을 측정해 통계를 보여주며, 탄소 배출 감축량 정보를 담은
WIMPLE 사용 레포트를 제공합니다.`,
    descriptionMobile: `기기 사용에 따라 분류된 폐기물 품목 및 양을 측정해
통계를 보여주며, 탄소 배출 감축량 정보를 담은
WIMPLE 사용 레포트를 제공합니다.`,
  },
];
