/** @jsxImportSource @emotion/react */
import { MedicalImage } from "../../../../assets/img/img";
import useBasePadding from "../../../../hooks/useBasePadding";
import useResponsive from "../../../../hooks/useResponsive";

const texts = {
  content: `현재 병·의원에서 보급되어 사용되는 캡슐 내시경과 호환이 가능합니다.`,
  contentMobile: `현재 병·의원에서 보급되어 사용되는
캡슐 내시경과 호환이 가능합니다.`,
};
export default function Section5() {
  const { GOLBAL_PADDING } = useBasePadding();
  const { isPc } = useResponsive();
  return (
    <div css={wrapper(GOLBAL_PADDING, !isPc)}>
      <pre css={title(!isPc)}>시중 캡슐 내시경과 호환 가능</pre>
      {!isPc ? (
        <pre css={description(!isPc)}>{texts.contentMobile}</pre>
      ) : (
        <pre css={description(!isPc)}>{texts.content}</pre>
      )}
      <div css={compatibility(!isPc)}>
        <img css={!isPc ? { width: "250px" } : ""} src={MedicalImage.Compatibility.LeftImage} alt="LeftImage" />
        <div>
          <img
            css={!isPc ? { transform: "rotate(90deg)", width: "58px" } : {}}
            src={MedicalImage.Compatibility.BothArrow}
            alt="BothArrow"
          />
        </div>
        <img css={!isPc ? { width: "250px" } : ""} src={MedicalImage.Compatibility.RightImage} alt="RightImage" />
      </div>
    </div>
  );
}

const wrapper = (padding: string, isMobile: boolean) => ({
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
  paddingLeft: padding,
  paddingRight: padding,
  background: "black",
  marginTop: isMobile ? "100px" : "200px",
});

const title = (isMobile: boolean) => ({
  color: "white",
  fontSize: isMobile ? "22px" : "32px",
  fontWeight: "600",
  marginBottom: "15px",
});

const description = (isMobile: boolean) => ({
  color: "white",
  fontSize: isMobile ? "14px" : "18px",
  fontWeight: "500",
  lineHeight: "27px",
  marginBottom: isMobile ? "50px" : "100px",
});

const compatibility = (isMobile: boolean) => ({
  display: "flex",
  flexDirection: isMobile ? ("column" as const) : ("row" as const),
  alignItems: "center",
  gap: "35px",
});
