/** @jsxImportSource @emotion/react */

interface PropsType {
  handleClick: (index: number) => void;
  isClick: number;
}

export default function TextComponent({ isClick, handleClick }: PropsType) {
  return (
    <div css={contentBox} onMouseLeave={() => handleClick(0)}>
      <div css={content1} onMouseEnter={() => handleClick(0)}>
        <p css={engTitle(isClick === 0)}>Lesion Detection</p>
        <p css={korTitle(isClick === 0)}>병변 검출</p>
        <div css={description(isClick === 0)}>
          AI를 통해 소장 내 &nbsp;
          <p css={stress(isClick === 0)}>
            궤양, 용종, 림프관 확장, 출혈 검출
          </p>{" "}
          이 가능
        </div>
      </div>
      <div css={content2} onMouseEnter={() => handleClick(1)}>
        <p css={engTitle(isClick === 1)}>Transition Classification</p>
        <p css={korTitle(isClick === 1)}>소장 구간</p>
        <div css={description(isClick === 1)}>
          AI를 통해 &nbsp;
          <p css={stress(isClick === 1)}>소장 시작 구간, 소장 끝 구간을 추정</p>
          &nbsp; 가능
        </div>
      </div>
      <div css={content3} onMouseEnter={() => handleClick(2)}>
        <p css={engTitle(isClick === 2)}>Visualization Scale</p>
        <p css={korTitle(isClick === 2)}>장 정결도</p>
        <div css={description(isClick === 2)}>
          AI를 통해&nbsp;
          <p css={stress(isClick === 2)}>장 정결도 측정 </p>&nbsp;가능
        </div>
      </div>
    </div>
  );
}

const engTitle = (isOn: boolean) => ({
  color: isOn ? "white" : "#D9D9D9",
  fontSize: "18px",
  fontWeight: "400",
});

const korTitle = (isOn: boolean) => ({
  color: isOn ? "white" : "#D9D9D9",
  fontSize: "30px",
  fontWeight: "600",
});

const description = (isOn: boolean) => ({
  display: "flex",
  color: isOn ? "white" : "#D9D9D9",
  fontSize: "18px",
  fontWeight: "400",
});

const stress = (isOn: boolean) => ({
  background: isOn
    ? "linear-gradient(90deg, #6DDCFF 0%, #7F60F9 100%)"
    : "#D9D9D9",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
});

const contentBox = {
  position: "relative" as const,
  width: "450px",
  height: "492px",
  display: "flex",
};

const content = {
  position: "absolute" as const,
  display: "flex",
  flexDirection: "column" as const,
  justifyContent: "center",
  left: "-15px",
  gap: "10px",
  width: "100%",
  height: "233px",
};

const content1 = {
  ...content,
  top: "0",
  transform: "translate(0%, -50%)",
};

const content2 = {
  ...content,
  top: "50%",
  transform: "translate(0%, -50%)",
};

const content3 = {
  ...content,
  top: "100%",
  transform: "translate(0%, -50%)",
};
