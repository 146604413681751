/** @jsxImportSource @emotion/react */
import { MedicalIcons } from "../../../../assets/icon/icon";
import useBasePadding from "../../../../hooks/useBasePadding";
import captosVideo from "../../../../assets/videos/captosVideo.mp4";
import useResponsive from "../../../../hooks/useResponsive";
export default function Section2() {
  const { GOLBAL_PADDING } = useBasePadding();
  const { isMobile, isTablet, isPc } = useResponsive();
  return (
    <div css={wrapper(GOLBAL_PADDING, !isPc)}>
      <img src={MedicalIcons.Section2.Section2Logo} alt="Section2Logo" />
      <video
        css={videoStyle(isMobile, isTablet)}
        autoPlay
        loop
        muted
        playsInline
      >
        <source src={captosVideo} type="video/mp4" />
      </video>
    </div>
  );
}

const wrapper = (padding: string, isMobile: boolean) => ({
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
  paddingLeft: padding,
  paddingRight: padding,
  background: "black",
  marginTop: isMobile ? "100px" : "200px",
});

const videoStyle = (isMobile: boolean, isTablet: boolean) => ({
  width: isMobile ? "375px" : isTablet ? "650px" : "1100px",
  height: isMobile ? "180px" : isTablet ? "400px" : "650px",
  marginTop: "100px",
  objectFit: "cover" as const,
});
