/** @jsxImportSource @emotion/react */
import logoImg from "../../../../assets/img/header/logo.svg";
import useHandleHistory from "../../../../hooks/useHandleHistory";
import videoTitle from "../../../../assets/img/Home/Title/titleVideo.mp4";
import useResponsive from "../../../../hooks/useResponsive";
import { ButtonImg } from "../../../../assets/Button/buttons";
import { useState } from "react";

const texts = {
  Title: `건강하고 지속가능한 미래를 만들어가기
위해 노력합니다.`,
  ContentPc: `서르는 인공지능 학습 데이터 가공 및 알고리즘 개발을 중심으로
의료 진단과 재활용 분류 산업에 인공지능을 활용한 서비스를 제공하여
인간이 건강하고 지속 가능한 미래에 살아갈 수 있도록 돕는 플랫폼 개발을 통해
삶을 더욱 윤택하게 만들어 갑니다.
`,
  ContentMobile: `서르는 인공지능 학습 데이터 가공 및 알고리즘 개발을
중심으로 의료 진단과 재활용 분류 산업에 인공지능을
활용한 서비스를 제공하여 인간이 건강하고
지속 가능한 미래에 살아갈 수 있도록 돕는
플랫폼 개발을 통해 삶을 더욱 윤택하게 만들어 갑니다.
`,
};

export default function HomeTitle() {
  const { handleHistoryPush } = useHandleHistory();
  const { isTablet, isMobile } = useResponsive();
  const [buttonSrc, setButtonSrc] = useState<string>(ButtonImg.contact1);

  return (
    <div css={MainContent(isTablet, isMobile)}>
      <video css={videoStyle} autoPlay loop muted playsInline>
        <source src={videoTitle} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div css={textBox}>
        <img css={logoSize} src={logoImg} alt="로고" />
        {isMobile ? (
          <pre css={SubTItleText(isMobile)}>{texts.Title}</pre>
        ) : (
          <p css={SubTItleText(isMobile)}>{texts.Title}</p>
        )}
        {isMobile ? (
          <pre css={contentText(isMobile)}>{texts.ContentMobile}</pre>
        ) : (
          <pre css={contentText(isMobile)}>{texts.ContentPc}</pre>
        )}
      </div>
      <img
        onClick={() => {
          handleHistoryPush("/Contact");
        }}
        src={buttonSrc}
        alt="contact"
        css={buttonImage}
        onMouseEnter={() => setButtonSrc(ButtonImg.contact1Hover)}
        onMouseLeave={() => setButtonSrc(ButtonImg.contact1)}
      />
    </div>
  );
}

const MainContent = (isTablet: boolean, isMobile: boolean) => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column" as const,
  height: "100vh",
  padding: isMobile ? "0 40px" : isTablet ? "0 100px" : "0 320px",
  position: "relative" as const,
  overflow: "hidden",
});

const textBox = {
  display: "flex",
  flexDirection: "column" as const,
  gap: "15px",
  marginBottom: "50px",
  maxWidth: "582px",
  zIndex: 1,
};

const logoSize = {
  width: "179.42px",
  height: "20px",
};

const SubTItleText = (isMobile: boolean) => ({
  fontSize: isMobile ? "16px" : "18px",
  fontWeight: 700,
  lineHeight: "21.48px",
  textAlign: "left" as const,
  color: "white",
});

const contentText = (isMobile: boolean) => ({
  fontSize: isMobile ? "15px" : "18px",
  fontWeight: 400,
  lineHeight: "27px",
  textAlign: "left" as const,
  color: "#fff",
});

const videoStyle = {
  position: "absolute" as const,
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover" as const,
  zIndex: 0,
  filter: "brightness(0.4)",
};

const buttonImage = {
  width: "180px",
  height: "65px",
  cursor: "pointer",
  zIndex: 1,
};
