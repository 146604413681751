/** @jsxImportSource @emotion/react */
import useResponsive from "../../../../../hooks/useResponsive";

export default function Year2021() {
  const { isMobile } = useResponsive();
  return (
    <div css={yearStyle(isMobile)}>
      <div css={textBox(isMobile)}>
        <p css={numberText(isMobile)}>07</p>
        <p css={text(isMobile)}>기업부설연구소 인증</p>
      </div>
      <div css={textBox(isMobile)}>
        <p css={numberText(isMobile)}>05</p>
        <div css={Texts}>
          <p css={text(isMobile)}>NIA, 헬스케어 분야 인공지능 학습용 데이터 구축 사업 참여 (캡슐내시경 외)</p>
          <p css={text(isMobile)}>KDATA, 데이터바우처 공급기업 선정</p>
        </div>
      </div>
      <div css={textBox(isMobile)}>
        <p css={numberText(isMobile)}>01</p>
        <div css={Texts}>
          <p css={text(isMobile)}>인공지능 그랜드 챌린지(사물인지 트랙) 2위 수상</p>
          <p css={text(isMobile)}>IITP, 인공지능 산업 원천 기술 개발 사업 참여(R&D)</p>
        </div>
      </div>
    </div>
  );
}

const yearStyle = (isMobile: boolean) => ({
  display: "flex",
  flexDirection: "column" as const,
  gap: "10px",
  marginTop: isMobile ? "322px" : "368px",
  marginBottom: isMobile ? "68px" : undefined,
});

const textBox = (isMobile: boolean) => ({
  display: "flex",
  flexDirection: "row" as const,
  gap: isMobile ? "15px" : "30px",
});

const Texts = {
  display: "flex",
  flexDirection: "column" as const,
  gap: "10px",
};

const numberText = (isMobile: boolean) => ({
  fontSize: isMobile ? "14px" : "20px",
  fontWeight: 700,
  lineHeight: "27px",
  textAlign: "center" as const,
  color: "white",
});

const text = (isMobile: boolean) => ({
  fontSize: isMobile ? "14px" : "18px",
  fontWeight: 400,
  lineHeight: "27px",
  textAlign: "left" as const,
  color: "white",
});
