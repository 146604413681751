/** @jsxImportSource @emotion/react */
import { useEffect, useState, useRef } from "react";
import { HomeImg } from "../../../../assets/img/img";
import useResponsive from "../../../../hooks/useResponsive";
import backgroundImg from "../../../../assets/img/Home/VIsion/background.svg";
import { ButtonImg } from "../../../../assets/Button/buttons";

const texts = {
  title: `오늘보다 내일 더 나은 미래를 만들어 가는 기업 서르`,
  titleMobile: `오늘보다 내일 더 나은 미래를
만들어 가는 기업 서르`,
  혁신적인기업: `기존의 제품과 서비스의 불편한 점을
개선하는 플랫폼 개발로 새로운 시장을
창출하여 차별화에 앞섭니다.`,
  미래지향적기업: `지속 가능한 미래를 만들어 가기 위하여
순환경제 플랫폼을 개발하고
탄소 배출 감량에 힘쓰고 있습니다.`,
  사람을생각하는기업: `사람과 인공지능 기술을 연결하여 
사람이 더 나은 미래를 살아가기 위한
솔루션을 만들어 나갑니다.`,
};

export default function Vision() {
  const { isTablet, isMobile } = useResponsive();
  const [backgroundOpacity, setBackgroundOpacity] = useState(0);
  const visionRef = useRef<HTMLDivElement>(null);
  const [buttonSrc, setButtonSrc] = useState<string>(ButtonImg.회사소개서다운로드);

  useEffect(() => {
    const handleScroll = () => {
      if (visionRef.current) {
        const { top, height } = visionRef.current.getBoundingClientRect();
        const viewportHeight = document.documentElement.clientHeight;

        const visiblePercentage = Math.min(1, Math.max(0, (viewportHeight - top) / height));

        setBackgroundOpacity(visiblePercentage);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div ref={visionRef} css={visionStyle(backgroundOpacity, isTablet, isMobile)}>
      <div css={isVisionContentMobile(isTablet, isMobile)}>
        <div css={textBox}>
          <p css={text400}>Vision & Mission</p>
          <p css={text600}>MORE THAN NOW</p>
          {isMobile ? <pre css={text500}>{texts.titleMobile}</pre> : <p css={text500}>{texts.title}</p>}
        </div>
        <div css={isImageContentMobile(isTablet, isMobile)}>
          <div css={isContentDetailMobile(isMobile)}>
            <img css={contentImgSize} src={HomeImg.혁신적인기업} alt="혁신적인 기업" />
            <div css={contentTextBox}>
              <p css={text700}>혁신적인 기업</p>
              <pre css={text400}>{texts.혁신적인기업}</pre>
            </div>
          </div>
          <div css={isContentDetailMobile(isMobile)}>
            <img css={contentImgSize} src={HomeImg.미래지향적인기업} alt="미래지향적인 기업" />
            <div css={contentTextBox}>
              <p css={text700}>미래지향적인 기업</p>
              <pre css={text400}>{texts.미래지향적기업}</pre>
            </div>
          </div>
          <div css={isContentDetailMobile(isMobile)}>
            <img css={contentImgSize} src={HomeImg.사람을생각하는기업} alt="사람을 생각하는 기업" />
            <div css={contentTextBox}>
              <p css={text700}>사람을 생각하는 기업</p>
              <pre css={text400}>{texts.사람을생각하는기업}</pre>
            </div>
          </div>
        </div>
        <a
          href="https://drive.google.com/file/d/1hXaB2bj-OB_FJtKA4DYSHp51G7VnxxX0/view?usp=sharing"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={buttonSrc}
            alt="회사소개서 다운로드"
            css={ButtonStyle}
            onMouseEnter={() => setButtonSrc(ButtonImg.회사소개서Hover)}
            onMouseLeave={() => setButtonSrc(ButtonImg.회사소개서다운로드)}
          />
        </a>
      </div>
    </div>
  );
}

const visionStyle = (backgroundOpacity: number, isTablet: boolean, isMobile: boolean) => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column" as const,
  background: "black",
  height: "auto",
  gap: "50px",
  backgroundImage: `linear-gradient(rgba(0, 0, 0, ${1 - backgroundOpacity}), rgba(0, 0, 0, ${
    1 - backgroundOpacity
  })), url(${backgroundImg})`,
  backgroundSize: isMobile ? "400%" : isTablet ? "150%" : "60%",
  backgroundRepeat: "no-repeat",
  backgroundPosition: isTablet ? "60% center" : "top",
  transition: "background-image 0.5s ease-in-out",
});

const visionContent = {
  display: "flex",
  flexDirection: "column" as const,
  justifyContent: "space-between",
  alignItems: "center",
  gap: "150px",
  padding: "150px 16.67%",
};

const visionContentTablet = {
  ...visionContent,
  padding: "100px 7%",
  gap: "100px",
};

const visionContentMobile = {
  ...visionContentTablet,
  gap: "50px",
};

const isVisionContentTablet = (isTablet: boolean) => (isTablet ? visionContentTablet : visionContent);
const isVisionContentMobile = (isTablet: boolean, isMobile: boolean) =>
  isMobile ? visionContentMobile : isVisionContentTablet(isTablet);

const textBox = {
  display: "flex",
  flexDirection: "column" as const,
  gap: "15px",
};

const text400 = {
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "27px",
  textAlign: "center" as const,
  color: "white",
};

const text500 = {
  fontSize: "18px",
  fontWeight: 500,
  lineHeight: "27px",
  textAlign: "center" as const,
  color: "white",
};

const text600 = {
  fontSize: "32px",
  fontWeight: 600,
  lineHeight: "38.19px",
  textAlign: "center" as const,
  color: "white",
};

const text700 = {
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "23.87px",
  textAlign: "center" as const,
  color: "white",
};

const ImageContent = {
  display: "flex",
  flexDirection: "row" as const,
  gap: "240px",
};

const ImageContentTablet = {
  ...ImageContent,
  gap: "40px",
  "@media (max-width: 1200px)": {
    display: "grid",
    gridTemplateColunms: "repeat(1, 1fr)",
  },
};

const ImageContentMobile = {
  ...ImageContentTablet,
  flexDirection: "column" as const,
};

const isImageContentTablet = (isTablet: boolean) => (isTablet ? ImageContentTablet : ImageContent);
const isImageContentMobile = (isTablet: boolean, isMoblie: boolean) =>
  isMoblie ? ImageContentMobile : isImageContentTablet(isTablet);

const ContentDetail = {
  display: "flex",
  flexDirection: "column" as const,
  justifyContent: "center",
  alignItems: "center",
  gap: "70px",
};

const ContentDetailMobile = {
  ...ContentDetail,
  gap: "35px",
};

const isContentDetailMobile = (isMobile: boolean) => (isMobile ? ContentDetailMobile : ContentDetail);

const contentImgSize = {
  width: "100px",
  height: "100px",
};

const contentTextBox = {
  display: "flex",
  flexDirection: "column" as const,
  gap: "20px",
  justifyContent: "center",
  textAlign: "center" as const,
  maxWidth: "272px",
};

const ButtonStyle = {
  width: "300px",
  height: "65px",
  cursor: "pointer",
};
