import step1 from "../icon/AiData/1.svg";
import step2 from "../icon/AiData/2.svg";
import step3 from "../icon/AiData/3.svg";
import step4 from "../icon/AiData/4.svg";
import step5 from "../icon/AiData/5.svg";
import step6 from "../icon/AiData/6.svg";
import step7 from "../icon/AiData/7.svg";
import step8 from "../icon/AiData/8.svg";
import circle from "../icon/AiData/circle.svg";
import circleBorder from "../icon/AiData/circle_border.svg";

import wIcon from "../icon/Recycle/w.png";
import wimpleLogo from "../icon/Recycle/WimpleLogo.png";
import arrow from "../icon/Recycle/Arrow.png";
import processIcon from "../icon/Recycle/ProcessIcon.png";
import WEE from "../icon/Recycle/WEE.png";

import WimpleLogo from "../icon/Recycle/KeyPoint/WIMPLE.png";
import Point1 from "../icon/Recycle/KeyPoint/Point 01.png";
import Point2 from "../icon/Recycle/KeyPoint/Point 02.png";
import Point3 from "../icon/Recycle/KeyPoint/Point 03.png";

import DeliveryMethod1 from "../icon/Recycle//DeliveryMethod/1.png";
import DeliveryMethod2 from "../icon/Recycle//DeliveryMethod/2.png";
import DeliveryMethod3 from "../icon/Recycle//DeliveryMethod/3.png";
import DeliveryMethod4 from "../icon/Recycle//DeliveryMethod/4.png";

import MedcialLogo from "../icon/Medical/Logo.png";
import Section2Logo from "../icon/Medical/Section2Logo.png";

export const AiDataIcons = {
  step1,
  step2,
  step3,
  step4,
  step5,
  step6,
  step7,
  step8,
  circle,
  circleBorder,
};

export const RecycleIcons = {
  wIcon,
  wimpleLogo,
  arrow,
  processIcon,
  WEE,
  KeyPoint: {
    WimpleLogo,
    Point1,
    Point2,
    Point3,
  },
  DeliveryMehod: {
    DeliveryMethod1,
    DeliveryMethod2,
    DeliveryMethod3,
    DeliveryMethod4,
  },
};

export const MedicalIcons = {
  MedicalMain: {
    MedcialLogo,
  },
  Section2: {
    Section2Logo,
  },
};
