/** @jsxImportSource @emotion/react */
import { RecycleImage } from "../../../../assets/img/img";
import { RecycleIcons } from "../../../../assets/icon/icon";
import useHandleHistory from "../../../../hooks/useHandleHistory";
import { ButtonImg } from "../../../../assets/Button/buttons";
import useResponsive from "../../../../hooks/useResponsive";
import { useState } from "react";

const texts = {
  Title: "AI를 통해 만들어 나가는 지구의 순환 경제 플랫폼",
  ContentPc: `매년 증가하는 폐기물 발생량과  폐기물 처리 비용 
사람의 눈과 손에 의존하는 현재 폐기물 선별 시스템으로는 재활용률 개선이 어렵습니다.
서르는 인공지능 기반의 분리수거 자동화 플랫폼을 통해 기존 분리수거 및 선별 프로세스를 개선합니다. `,
  ContentTablet: `매년 증가하는 폐기물 발생량과  폐기물 처리 비용 
사람의 눈과 손에 의존하는 현재 폐기물 선별 시스템으로는 재활용률
개선이 어렵습니다.
서르는 인공지능 기반의 분리수거 자동화 플랫폼을 통해
기존 분리수거 및 선별 프로세스를 개선합니다.`,
  ContentMobile: `매년 증가하는 폐기물 발생량과 폐기물 처리 비용
사람의 눈과 손에 의존하는 현재 폐기물
선별 시스템으로는 재활용률 개선이 어렵습니다.
서르는 인공지능 기반의 분리수거 자동화 플랫폼을 통해
기존 분리수거 및 선별 프로세스를 개선합니다. 
  `,
};

export default function RecycleMain() {
  const { handleHistoryPush } = useHandleHistory();
  const [buttonSrc, setButtonSrc] = useState<string>(ButtonImg.contact1);
  const { isTablet, isMobile } = useResponsive();
  return (
    <div css={wrapper(isTablet, isMobile)}>
      <div css={textBox}>
        <img src={RecycleIcons.wIcon} alt="wIcon" css={{ width: "160px", height: "50px" }} />
        <pre css={SubTItleText(isMobile)}>{texts.Title}</pre>
        {isMobile ? (
          <pre css={contentText(isMobile)}>{texts.ContentMobile}</pre>
        ) : isTablet ? (
          <pre css={contentText(isMobile)}>{texts.ContentTablet}</pre>
        ) : (
          <pre css={contentText(isMobile)}>{texts.ContentPc}</pre>
        )}
      </div>
      <img
        onClick={() => {
          handleHistoryPush("/Contact");
        }}
        src={buttonSrc}
        onMouseEnter={() => setButtonSrc(ButtonImg.contact1Hover)}
        onMouseLeave={() => setButtonSrc(ButtonImg.contact1)}
        alt="contact"
        css={buttonImage}
      />
    </div>
  );
}

const wrapper = (isTablet: boolean, isMobile: boolean) => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column" as const,
  height: "100vh",
  padding: isMobile ? "0 40px" : isTablet ? "0 100px" : "0 320px",
  position: "relative" as const,
  overflow: "hidden",
  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${RecycleImage.background})`,
  backgroundOrigin: "border-box",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  gap: "50px",
});

const textBox = {
  display: "flex",
  flexDirection: "column" as const,
  gap: "15px",
};

const SubTItleText = (isMobile: boolean) => ({
  fontSize: isMobile ? "16px" : "18px",
  fontWeight: 700,
  lineHeight: "21.48px",
  textAlign: "left" as const,
  color: "white",
});

const contentText = (isMobile: boolean) => ({
  fontSize: isMobile ? "15px" : "18px",
  fontWeight: 400,
  lineHeight: "27px",
  textAlign: "left" as const,
  color: "#fff",
});

const buttonImage = {
  width: "180px",
  height: "65px",
  cursor: "pointer",
};
