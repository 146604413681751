/** @jsxImportSource @emotion/react */
import { useMemo } from "react";
import { customerImg } from "../../../../assets/img/Home/Customer/customer";
import useResponsive from "../../../../hooks/useResponsive";

export default function Customer() {
  const { isTablet, isMobile } = useResponsive();

  const wrapperStyle = useMemo(() => customerStyle(isTablet), [isTablet]);
  const contentStyle = useMemo(() => customerContent(isMobile), [isMobile]);

  const renderImages = () =>
    customerImg.map((imgSrc, index) => (
      <img
        key={imgSrc}
        src={imgSrc}
        alt={`customer-${index}`}
        css={imageStyle}
      />
    ));

  return (
    <div css={wrapperStyle}>
      <div css={contentStyle}>
        <p css={textTitle}>PARTNERS</p>
        <div css={imagesContainer}>{renderImages()}</div>
      </div>
    </div>
  );
}

const customerStyle = (isTablet: boolean) =>
  ({
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    background: "black",
    height: "auto",
    gap: "50px",
    padding: isTablet ? "0 7% 200px" : "0 17.67% 200px",
  } as const);

const customerContent = (isMobile: boolean) =>
  ({
    display: "flex",
    flexDirection: "column",
    gap: isMobile ? "30px" : "100px",
    alignItems: "center",
    height: "100%",
  } as const);

const textTitle = {
  fontSize: "32px",
  fontWeight: 600,
  lineHeight: "38.19px",
  textAlign: "center",
  color: "white",
} as const;

const imagesContainer = {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  gap: "14px",
} as const;

const imageStyle = {
  width: "auto",
  height: "33px",
  "@media (max-width: 400px)": {
    width: "114px",
  } as const,
};
