/** @jsxImportSource @emotion/react */
import { RecycleIcons } from "../../../../assets/icon/icon";
import gif1 from "../../../../assets/gif/1.gif";
import gif2 from "../../../../assets/gif/2.gif";
import useBasePadding from "../../../../hooks/useBasePadding";
import { RecycleImage } from "../../../../assets/img/img";
import useResponsive from "../../../../hooks/useResponsive";

const texts = {
  contentPc: `WIMPLE에 탑재된 인공지능 WEE는 자사에서 직접 구축한 고품질 생활 폐기물 데이터를 기반으로 환경부 분리배출 기준에 맞춘
지속적인 외형 학습을 통해 98%의 높은 인식률로 투입 폐기물의 재활용 분류 품목, 형태, 색상, 이물질 여부, 다중 포장재 여부를 정확하게 판단합니다.`,
  contentMobie: `WIMPLE에 탑재된 인공지능 WEE는 자사에서
직접 구축한 고품질 생활 폐기물 데이터를 기반으로
환경부 분리배출 기준에 맞춘 지속적인 외형 학습을 통해
98%의 높은 인식률로 투입 폐기물의
재활용 분류 품목, 형태, 색상, 이물질 여부, 다중 포장재 여부를
정확하게 판단합니다.`,
};
export default function Algorithm() {
  const { GOLBAL_PADDING } = useBasePadding();
  const { isPc, isMobile, isTablet } = useResponsive();
  return (
    <div css={wrapper(GOLBAL_PADDING, !isPc, isTablet)}>
      <p css={subTitle(!isPc)}>
        생활 폐기물 인식 알고리즘{" "}
        <div>
          {" "}
          <img
            css={!isPc ? { height: "24px" } : {}}
            src={RecycleIcons.WEE}
            alt="coloredLogo"
          />
        </div>
      </p>
      {!isPc ? (
        <pre css={description(!isPc)}>{texts.contentMobie}</pre>
      ) : (
        <pre css={description(!isPc)}>{texts.contentPc}</pre>
      )}
      <div
        css={{
          position: "relative",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          width: !isPc ? (!isMobile ? "600px" : "375px") : "1280px",
          justifyContent: "center",

          zIndex: 2,
        }}
      >
        <img
          css={[visualImg(!isPc, !isMobile), { transform: "translateX(20%)" }]}
          src={gif1}
          alt="visualImg"
        />
        <img
          css={[visualImg(!isPc, !isMobile), { transform: "translateX(-20%)" }]}
          src={gif2}
          alt="visualImg"
        />
        <img
          css={background(!isPc, !isMobile)}
          src={RecycleImage.recycleGradation}
          alt="girBackground"
        />
      </div>
    </div>
  );
}

const wrapper = (padding: string, isMobile: boolean, isTablet: boolean) => ({
  position: "relative" as const,
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
  paddingLeft: padding,
  paddingRight: padding,
  background: "black",
  marginTop: isMobile ? "75px" : "200px",
  // backgroundImage: `url(${RecycleImage.recycleGradation})`,
  // backgroundPosition: "center bottom",
  // backgroundRepeat: "no-repeat",
  // backgroundSize: "contain 120%",
  marginBottom: isMobile ? (isTablet ? "50px" : "100px") : "",
});

const background = (isMobile: boolean, isTablet: boolean) => ({
  position: "absolute" as const,
  width: isMobile ? (isTablet ? "600px" : "350px") : "",
  contentFit: "cover",
  top: "100%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: "-1",
});

const subTitle = (isMobile: boolean) => ({
  fontSize: isMobile ? "24px" : "32px",
  fontWeight: "600",
  color: "white",
  marginBottom: "15px",
  lineHeight: "38px",
  display: "flex",
  flexDirection: isMobile ? ("column" as const) : ("row" as const),
  gap: !isMobile ? "8px" : "",
  alignItems: isMobile ? "center" : "",
});

const description = (isMobile: boolean) => ({
  color: "white",
  fontSize: isMobile ? "14px" : "18px",
  fontWeight: "500",
  lineHeight: "27px",
  textAlign: "center" as const,
});

const visualImg = (isMobile: boolean, isTablet: boolean) => ({
  marginTop: isMobile ? "24px" : "100px",
  width: isMobile ? (isTablet ? "390px" : "200px") : "800px",
  height: isMobile ? (isTablet ? "230px" : "120px") : "450px",
});
