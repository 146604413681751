/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { TindingData } from "./stractor";
import { Icon } from "../../../../assets/img/img";
import useResponsive from "../../../../hooks/useResponsive";

export default function Tinding() {
  const { isTablet, isMobile } = useResponsive();
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const handleSlideChange = (swiper: any) => {
    setActiveIndex(swiper.realIndex);
  };

  return (
    <div css={TindingStyle}>
      <p css={textTitle}>서르의 다양한 소식을 만나보세요</p>
      <div css={swiperContainer}>
        <Swiper
          modules={[Navigation, Pagination]}
          navigation={{
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next",
          }}
          pagination={{
            clickable: true,
            renderBullet: () => {
              return "";
            },
          }}
          centeredSlides={true}
          loop={true}
          spaceBetween={isMobile ? 20 : 0}
          onSlideChange={handleSlideChange}
          breakpoints={{
            1800: {
              slidesPerView: 1,
            },
            1801: {
              slidesPerView: 3,
            },
          }}
        >
          {TindingData.map((item, index) => (
            <SwiperSlide
              key={item.title}
              style={{
                transform: `scale(${index === activeIndex ? 1 : 0.8})`,
              }}
            >
              <div css={style}>
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  <img
                    src={item.image}
                    alt={item.title}
                    css={isMobileSlideImage(isMobile)}
                  />
                </a>
                <div>
                  <p css={newsText}>{item.title}</p>
                  <p css={newsText}>{item.date}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
          <div className="swiper-button-prev" css={leftArrow}></div>
          <div className="swiper-button-next" css={rightButton}></div>
        </Swiper>
      </div>
      <a href="https://www.youtube.com/@seoreu6610">
        <div css={Youtube}>
          <img src={Icon.유튜브} alt="youtube" css={youtubeIcon} />
          <p css={youtubeText}>서르 Youtube 채널 바로가기</p>
        </div>
      </a>
    </div>
  );
}

const arrowButton = {
  color: "white",
  position: "absolute" as const,
  top: "35%",
};

const leftArrow = {
  ...arrowButton,
  left: "31%",
  "@media (max-width: 1800px)": {
    left: "10%",
    top: "30%",
  },
  "@media (max-width: 1000px)": {
    left: "5%",
    top: "30%",
  },
  "@media (max-width: 600px)": {
    left: "2%",
  },
};

const rightButton = {
  ...arrowButton,
  right: "31%",
  "@media (max-width: 1800px)": {
    right: "10%",
    top: "30%",
  },
  "@media (max-width: 1000px)": {
    right: "5%",
    top: "30%",
  },
  "@media (max-width: 600px)": {
    right: "2%",
  },
};

const TindingStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "column" as const,
  background: "black",
  height: "auto",
  paddingBottom: "200px",
  gap: "100px",
};

const textTitle = {
  fontSize: "32px",
  fontWeight: 600,
  lineHeight: "38.19px",
  textAlign: "center" as const,
  color: "white",
};

const Youtube = {
  display: "flex",
  flexDirection: "row" as const,
  justifyContent: "center" as const,
  alignItems: "center",
  gap: "20px",
};

const youtubeIcon = {
  width: "42.86px",
  height: "30px",
};

const youtubeText = {
  fontSize: "25px",
  fontWeight: 700,
  lineHeight: "23.87px",
  textAlign: "center" as const,
  color: "white",
  ":hover": {
    textDecoration: "underline",
  },
};

const newsText = {
  fontSize: "18px",
  fontWeight: 400,
  lineHeight: "27px",
  textAlign: "center" as const,
  color: "white",
};

const swiperContainer = {
  width: "100%",
};

const style = {
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
  justifyContent: "space-between",
  textAlign: "center" as const,
  gap: "20px",
};

const slideImage = {
  width: "600px",
  height: "350px",
  objectFit: "cover" as const,
  objectPosition: "center 30%",
  transition: "height 0.3s ease",
  "@media (max-width: 1000px)": {
    width: "500px",
    height: "300px",
  },
  "@media (max-width: 600px)": {
    width: "285px",
    height: "185px",
  },
};

const slideImageMobile = {
  ...slideImage,
  width: "200px",
  height: "300px",
};

const isMobileSlideImage = (isMobile: boolean) =>
  isMobile ? slideImageMobile : slideImage;
