/** @jsxImportSource @emotion/react */
import useResponsive from "../../../../../hooks/useResponsive";

export default function Year2020() {
  const { isMobile } = useResponsive();
  return (
    <div css={yearStyle(isMobile)}>
      <div css={textBox(isMobile)}>
        <p css={numberText(isMobile)}>09</p>
        <p css={text(isMobile)}>NIA, 인공지능 학습용 데이터 구축 사업 참여 (헬스케어분야)</p>
      </div>
      <div css={textBox(isMobile)}>
        <p css={numberText(isMobile)}>03</p>
        <p css={text(isMobile)}>벤처기업인증</p>
      </div>
    </div>
  );
}

const yearStyle = (isMobile: boolean) => ({
  display: "flex",
  flexDirection: "column" as const,
  gap: "10px",
  marginTop: isMobile ? "427px" : "485px",
  marginBottom: isMobile ? "185px" : undefined,
});

const textBox = (isMobile: boolean) => ({
  display: "flex",
  flexDirection: "row" as const,
  gap: isMobile ? "15px" : "30px",
});

const Texts = {
  display: "flex",
  flexDirection: "column" as const,
  gap: "10px",
};

const numberText = (isMobile: boolean) => ({
  fontSize: isMobile ? "14px" : "20px",
  fontWeight: 700,
  lineHeight: "27px",
  textAlign: "center" as const,
  color: "white",
});

const text = (isMobile: boolean) => ({
  fontSize: isMobile ? "14px" : "18px",
  fontWeight: 400,
  lineHeight: "27px",
  textAlign: "left" as const,
  color: "white",
});
