import { AwardImg } from "../../../../assets/img/img";

export const awardsData = [
  {
    image: AwardImg.그랜드챌린지2위,
    title: "2020 인공지능 그랜드 챌린지",
    premier: "사물인지 트랙 2위",
  },
  {
    image: AwardImg.데이터바우처,
    title: "2022 데이터 바우처 지원사업",
    premier: "우수 성과상",
  },
  {
    image: AwardImg.IR피칭최우수상,
    title: "2022 Welcome to TIPS IR 피칭",
    premier: "최우수상",
  },
  {
    image: AwardImg.인공지능온라인경연,
    title: "2022 인공지능 온라인 경진대회",
    premier: "사물인지 트랙 2위",
  },
  {
    image: AwardImg.DX챌린지우수상,
    title: "2022 DX Challenge",
    premier: "우수상",
  },
  {
    image: AwardImg.산학연협력선도대학,
    title: "2022 산학연협력 선도대학",
    premier: "표창장",
  },
  {
    image: AwardImg.BIZ프로젝트공모전,
    title: "2022 Biz Project 공모전",
    premier: "은상",
  },
  {
    image: AwardImg.생산성CEO대상,
    title: "2024 한국 생산성 학회",
    premier: "생산성 CEO 대상",
  },
];
