/** @jsxImportSource @emotion/react */
import { useState } from "react";
import useBasePadding from "../../../../hooks/useBasePadding";
import LineHeader from "./modules/LineHeader";
import TextComponent from "./modules/TextComponent";
import ImageComponent from "./modules/ImageComponent";
import { MedicalImage } from "../../../../assets/img/img";
import useResponsive from "../../../../hooks/useResponsive";
import MobileSection3 from "./modules/MobileSection3";
export default function Section3() {
  const { GOLBAL_PADDING } = useBasePadding();
  const { isPc } = useResponsive();
  const [isClick, setIsClick] = useState<number>(0);
  const handleClick = (index: number) => {
    setIsClick(index);
  };

  const texts = {
    titlePc: `3개의 AI 엔진이 97-99%의 인식률로 주요 병변을 감지합니다.`,
    titleMobile: `3개의 AI 엔진이 97-99%의 인식률로
  주요 병변을 감지합니다.`,
  };

  return (
    <div css={wrapper(GOLBAL_PADDING)}>
      {!isPc ? <pre css={title(!isPc)}>{texts.titleMobile}</pre> : <pre css={title(!isPc)}>{texts.titlePc}</pre>}
      {!isPc ? (
        <MobileSection3 />
      ) : (
        <div css={contentLayout(!isPc)}>
          <div css={contentBox}>
            <LineHeader isClick={isClick} handleClick={handleClick} />
            <TextComponent isClick={isClick} handleClick={handleClick} />
          </div>
          <ImageComponent isClick={isClick} />
        </div>
      )}
      <div css={contentLayout(!isPc)}>
        <div css={contentWrapper(!isPc)}>
          <p css={bottomTitle(!isPc)}>임계값 설정 · 병변 검출 통계 제공</p>
          <p css={bottomDescription(!isPc)}>
            confidence level 조절로 <span css={stress}>병변 검출 성능 조정</span> 및 원하는 병변 선택 표시 병변 클래스와
            이상 부위 값에 따른 통계치 제공
          </p>
          <img src={MedicalImage.ai.BottomImg1} alt="bottom1" />
        </div>
        <div css={contentWrapper(!isPc)}>
          <p css={bottomTitle(!isPc)}>레포트 작성 · 제공</p>
          <p css={bottomDescription(!isPc)}>
            mark된 이미지에 discription을 작성하거나 동영상 전체에 대한 conciusion 작성 후 환자 정보와 인식 결과를
            포함한 <span css={stress}>레포트 자동 생성</span> 가능
          </p>
          <img src={MedicalImage.ai.BottomImg2} alt="bottom2" />
        </div>
      </div>
    </div>
  );
}

const wrapper = (padding: string) => ({
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
  paddingLeft: padding,
  paddingRight: padding,
  background: "black",
  marginTop: "100px",
});

const title = (isMobile: boolean) => ({
  color: "white",
  fontSize: isMobile ? "24px" : "32px",
  fontWeight: "600",
  marginBottom: "100px",
  textAlign: isMobile ? ("center" as const) : undefined,
});

const contentLayout = (isMobile: boolean) => ({
  width: isMobile ? "285px" : "1280px",
  display: "grid",
  gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
  gridTemplateRows: isMobile ? "1fr 1fr" : "1fr",
  gap: "16px",
  marginTop: isMobile ? "20px" : "",
  marginBottom: "20px",
});

const contentBox = {
  marginTop: "44px",
  display: "flex",
  gap: "50px",
  width: "100%",
  padding: "48px 58px",
};

const contentWrapper = (isMobile: boolean) => ({
  display: "flex",
  flexDirection: "column" as const,
  maxWidth: isMobile ? "285px" : "632px",
  height: isMobile ? "400px" : "700px",
  background: "#3D3D3D80",
  padding: isMobile ? "8px" : "80px 40px",
  boxSizing: "border-box" as const,
});

const bottomTitle = (isMobile: boolean) => ({
  color: "white",
  fontSize: isMobile ? "20px" : "30px",
  fontWeight: "600",
  marginBottom: "15px",
});

const bottomDescription = (isMobile: boolean) => ({
  color: "white",
  fontSize: isMobile ? "14px" : "18px",
  fontWeight: "400",
  marginBottom: isMobile ? "24px" : "100px",
  lineHeight: "27px",
});

const stress = {
  background: "linear-gradient(90deg, #6DDCFF 0%, #7F60F9 100%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
};
