/** @jsxImportSource @emotion/react */
import { Icon } from "../../assets/img/img";
import useHandleHistory from "../../hooks/useHandleHistory";
import useResponsive from "../../hooks/useResponsive";
import { useEffect, useState } from "react";
import { ButtonImg } from "../../assets/Button/buttons";

export default function Header() {
  const { handleHistoryPush, pathname } = useHandleHistory();
  const { isTablet, isMobile } = useResponsive();
  const [path, setPath] = useState<string>("");
  const [logoSrc, setLogoSrc] = useState<string>(Icon.로고);
  const [buttonSrc, setButtonSrc] = useState<string>(ButtonImg.contact2);
  useEffect(() => {
    setPath(pathname);
  }, [pathname]);

  const isActive = (menuPath: string) => path === menuPath;

  useEffect(() => {
    if (pathname !== "/Contact") {
      setButtonSrc(ButtonImg.contact2);
    }
  }, [pathname]);

  return (
    <div css={sticky}>
      <div css={isHeaderSize2(isMobile, isTablet)}>
        <div css={pageList}>
          <div
            css={Logopointer}
            onClick={() => {
              handleHistoryPush("/");
            }}
            onMouseEnter={() => setLogoSrc(Icon.호버로고)}
            onMouseLeave={() => setLogoSrc(Icon.로고)}
          >
            <img css={logoSize} src={logoSrc} alt="Logo" />
          </div>
          <div css={isLinksSize(isTablet)}>
            <p
              css={[text, Textpointer]}
              onClick={() => {
                handleHistoryPush("/AiData");
              }}
            >
              AI DATA
            </p>
            <p
              css={[text, Textpointer]}
              onClick={() => {
                handleHistoryPush("/Recycle");
              }}
            >
              RECYCLE
            </p>
            <p
              css={[text, Textpointer]}
              onClick={() => {
                handleHistoryPush("/Medical");
              }}
            >
              MEDICAL
            </p>
          </div>
        </div>
        {isMobile
          ? ""
          : pathname !== "/Contact" && (
              <img
                src={buttonSrc}
                onClick={() => {
                  handleHistoryPush("/Contact");
                }}
                onMouseEnter={() => setButtonSrc(ButtonImg.contact2Hover)}
                onMouseLeave={() => setButtonSrc(ButtonImg.contact2)}
                alt="contact"
                css={buttonImage}
              />
            )}
      </div>
      <div css={isLinkTablet(isTablet)}>
        <p
          css={[tabletText, Textpointer, isActive("/AiData") && activeLink]}
          onClick={() => {
            handleHistoryPush("/AiData");
          }}
        >
          AI DATA
        </p>
        <p
          css={[tabletText, Textpointer, isActive("/Recycle") && activeLink]}
          onClick={() => {
            handleHistoryPush("/Recycle");
          }}
        >
          RECYCLE
        </p>
        <p
          css={[tabletText, Textpointer, isActive("/Medical") && activeLink]}
          onClick={() => {
            handleHistoryPush("/Medical");
          }}
        >
          MEDICAL
        </p>
      </div>
    </div>
  );
}

const sticky = {
  position: "sticky" as const,
  top: 0,
  zIndex: 100,
  width: "100%",
};

const headerPc = {
  boxSizing: "border-box" as const,
  display: "flex",
  backgroundColor: "black",
  height: "80px",
  padding: "0 320px",
  alignItems: "center",
};

const headerTablet = {
  ...headerPc,
  padding: "0 100px",
};

const headerMobile = {
  ...headerTablet,
  padding: "0 20px",
};

const logoSize = {
  width: "134.57px",
  height: "15px",
};
const isHeaderSize = (isTablet: boolean) => (isTablet ? headerTablet : headerPc);
const isHeaderSize2 = (isMobile: boolean, isTablet: boolean) => (isMobile ? headerMobile : isHeaderSize(isTablet));

const pageList = {
  display: "flex",
  flexDirection: "row" as const,
  gap: "190px",
  alignItems: "center",
  width: "100%",
};

const LinksPc = {
  display: "flex",
  flexDirection: "row" as const,
  gap: "50px",
};

const LinksOutPc = {
  display: "none",
};

const isLinksSize = (isTablet: boolean) => (isTablet ? LinksOutPc : LinksPc);

const LinksTablet = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  gap: "50px",
  width: "100%",
  height: "30px",
  background: "black",
  borderTop: "1px solid #a1a1aa",
  padding: "12px 0",
  alignItems: "center",
};

const LinksOutTablet = {
  display: "none",
};

const isLinkTablet = (isTablet: boolean) => (isTablet ? LinksTablet : LinksOutTablet);

const text = {
  color: "white",
  fontFamily: "Pretendard",
  fontSize: "15px",
  fontWeight: 400,
  lineHeight: "17.9px",
  textAlign: "left" as const,
};

const tabletText = {
  ...text,
  fontSize: "20px",
};

const Textpointer = {
  cursor: "pointer",
  position: "relative",
  ":hover": {
    background: "linear-gradient(90deg, #6DDCFF 0%, #7F60F9 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    color: "transparent",
  },
  "::after": {
    content: '""',
    position: "absolute",
    left: 0,
    bottom: 0,
    height: "1px",
    width: "100%",
    background: "linear-gradient(90deg, #6DDCFF 0%, #7F60F9 100%)",
    transform: "scaleX(0)",
    transition: "transform 0.3s ease",
  },
  ":hover::after": {
    transform: "scaleX(1)",
  },
} as const;

const Logopointer = {
  cursor: "pointer",
};

const activeLink = {
  borderBottom: "2px solid #fff",
  fontWeight: "bold",
};

const buttonImage = {
  width: "120px",
  height: "40px",
  cursor: "pointer",
};
