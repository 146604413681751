import { useLocation, useNavigate } from "react-router-dom";

export default function useHandleHistory() {
	const navigate = useNavigate();
	const location = useLocation();
	const { pathname } = location;
	const handleHistoryPush = (path: string, state?: any) => navigate(path, { state });
	const handleHistoryReplace = (path: string) => navigate(path, { replace: true });
	const handleBack = () => navigate(-1);
	return { location, pathname, handleHistoryPush, handleHistoryReplace, handleBack };
}
