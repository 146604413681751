/** @jsxImportSource @emotion/react */
import { keyframes } from "@emotion/react";
import { DATA_VOUCHER_STRUCTURE } from "./structure";
import { useState, useEffect } from "react";
import useResponsive from "../../../../hooks/useResponsive";

const texts = {
  Title: `데이터 바우처 지원 사업 과학기술정보통신부 장관상 수여`,
  TitleTablet: `데이터 바우처 지원 사업
과학기술정보통신부 장관상 수여`,
  content: `서르는 중소기업, 소상공인, 의료기관의 데이터 구축과 AI 개발을 지원하기 위해 정부에서 시행하는 데이터 바우처, AI 바우처 사업에
2021년부터 지속적으로 공급기업으로 선정되어,  다양한 수요기업에 데이터와 AI 구축 사업을 수행하고 있습니다.`,
  contentTablet: `서르는 중소기업, 소상공인, 의료기관의 데이터 구축과
AI 개발을 지원하기 위해 정부에서 시행하는 데이터 바우처, AI 바우처 사업에
2021년부터 지속적으로 공급기업으로 선정되어,
다양한 수요기업에 데이터와 AI 구축 사업을 수행하고 있습니다.`,
  contentMobile: `서르는 중소기업, 소상공인,
의료기관의 데이터 구축과 AI 개발을 지원하기 위해
정부에서 시행하는 데이터 바우처,
AI 바우처 사업에 2021년부터 지속적으로
공급기업으로 선정되어,
다양한 수요기업에 데이터와 AI 구축 사업을
수행하고 있습니다.`,
};

export default function DataVaucher() {
  const [imagesLoad, setImagesLoad] = useState(false);
  const { isTablet, isMobile } = useResponsive();

  useEffect(() => {
    const loadImages = async () => {
      const promises = DATA_VOUCHER_STRUCTURE.map((item) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = item.image;
          img.onload = resolve;
        });
      });
      await Promise.all(promises);
      setImagesLoad(true);
    };

    loadImages();
  }, []);
  return (
    <div css={awardsStyle(isMobile)}>
      <div css={historyContent}>
        <div css={textBox(isTablet, isMobile)}>
          {isTablet ? (
            <pre css={title(isTablet, isMobile)}>{texts.TitleTablet}</pre>
          ) : (
            <pre css={title(isTablet, isMobile)}>{texts.Title}</pre>
          )}
          {isMobile ? (
            <pre css={description}>{texts.contentMobile}</pre>
          ) : isTablet ? (
            <pre css={description}>{texts.contentTablet}</pre>
          ) : (
            <pre css={description}>{texts.content}</pre>
          )}
        </div>
        <div css={slideContainer(isMobile)}>
          <div css={slideStyle}>
            {imagesLoad &&
              [...DATA_VOUCHER_STRUCTURE, ...DATA_VOUCHER_STRUCTURE].map((award) => (
                <div css={slideBoxStyle(award.image)} key={award.title}>
                  {/* <img src={award.image} alt={award.title} css={imageStyle} /> */}
                  <div
                    css={{
                      paddingLeft: "30px",
                      marginTop: "200px",
                    }}
                  >
                    <p css={slideTitleStyle}>{award.title}</p>
                    <p css={slideTextStyle}>{award.premier}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const infiniteSlide = keyframes({
  "0%": {
    transform: "translateX(0)",
  },
  "100%": {
    transform: "translateX(-50%)",
  },
});

const awardsStyle = (isMobile: boolean) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column" as const,
  background: "black",
  height: "auto",
  margin: isMobile ? "0 0 50px" : "200px 0",
});

const historyContent = {
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
  width: "100%",
  height: "100%",
};

const textBox = (isTablet: boolean, isMobile: boolean) => ({
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
  gap: "15px",
  padding: isTablet ? "100px 0 0" : ``,
});

const title = (isTablet: boolean, isMobile: boolean) => ({
  color: "white",
  fontWeight: 600,
  fontSize: isMobile ? "27px" : isTablet ? "30px" : "32px",
  marginBottom: "15px",
  textAlign: "center" as const,
});

const description = {
  color: "white",
  fontWeight: 500,
  fontSize: "18px",
  textAlign: "center" as const,
};

const slideContainer = (isMobile: boolean) => ({
  display: "flex",
  marginTop: isMobile ? "50px" : "100px",
  overflow: "hidden",
  width: "100%",
  willChange: "transform",
});

const slideStyle = {
  display: "flex",
  gap: "20px",
  paddingRight: "20px",
  animation: `${infiniteSlide} 50s linear infinite`,
};

const slideBoxStyle = (img: string) => ({
  display: "flex",
  flexDirection: "column" as const,
  gap: "20px",
  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${img})`,
  width: "426px",
  height: "300px",
  backgroundOrigin: "border-box",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
});

const slideTitleStyle = {
  fontSize: "20px",
  fontWeight: 700,
  lineHeight: "27px",
  color: "white",
  marginBottom: "10px",
};

const slideTextStyle = {
  fontSize: "15px",
  fontWeight: 400,
  lineHeight: "27px",
  color: "white",
};
