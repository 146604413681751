import { DataVaucher } from "../../../../assets/img/img";

export const DATA_VOUCHER_STRUCTURE = [
  {
    image: DataVaucher.DataVoucher1,
    title: "2021 데이터 바우처 지원 사업",
    premier: "재활용 쓰레기 선별 로봇 시스템의 AI 딥러닝 학습 데이터 구축",
  },
  {
    image: DataVaucher.DataVoucher2,
    title: "2021 데이터 바우처 지원 사업",
    premier: "인테리어 및 셀프 집 수리를 위한 플랫폼 고도화",
  },
  {
    image: DataVaucher.DataVoucher3,
    title: "2022 AI 바우처 지원 사업",
    premier: "인테리어 커머스 상품 추천 무드보드 AI 상품 패키지 제안",
  },
  {
    image: DataVaucher.DataVoucher4,
    title: "2022 데이터 바우처 지원 사업",
    premier: "IOT 기반 블루투스 비콘 위치 관제 서비스",
  },
  {
    image: DataVaucher.DataVoucher5,
    title: "2022 데이터 바우처 지원 사업",
    premier: "병변 판독을 위한 캡슐 내시경 영상 인공지능 학습 데이터",
  },
  {
    image: DataVaucher.DataVoucher6,
    title: "2023 데이터 바우처 지원 사업",
    premier: "AI 이미지 인지 기술을 활용한 자동 운전 제진기 시스템 개발",
  },
  {
    image: DataVaucher.DataVoucher7,
    title: "2023 데이터 바우처 지원 사업",
    premier: "CT 영상 내 안면골 골절 부위에 대한 영상 분할 데이터 가공",
  },
  {
    image: DataVaucher.DataVoucher8,
    title: "2023 데이터 바우처 지원 사업",
    premier:
      "ICT 기반 재활용품 통합 자동선별회수시스템 개발 및 블록체인 탄소감축 보상 플랫폼 연동",
  },
  {
    image: DataVaucher.DataVoucher9,
    title: "2023 데이터 바우처 지원 사업",
    premier: "AI 기술이 적용된 도심형 무인 투명 페트병 수집 로봇",
  },
  {
    image: DataVaucher.DataVoucher10,
    title: "2023 데이터 바우처 지원 사업",
    premier: "이미지 기반 딥러닝 용접비드 검사 시스템 개발  데이터셋 구축",
  },
  {
    image: DataVaucher.DataVoucher11,
    title: "2023 데이터 바우처 지원 사업",
    premier:
      "인공지능 기반 객체 탐지 및 이동 경로 추적 시스템 개발을 위한 다중 카메라 환경의 데이터 구축",
  },
  {
    image: DataVaucher.DataVoucher12,
    title: "2024 AI 바우처 지원 사업",
    premier:
      "소아 청소년 크론병 환자의 예후 향상을 위한 소장 캡슐내시경 병변 인식 및 위치 분석 솔루션",
  },
];
