/** @jsxImportSource @emotion/react */
import FinalContent from "../../component/final/finalContent";
import Awards from "./template/Awards";
import Customer from "./template/Customer";
import History from "./template/history";
import HomeTitle from "./template/HomeTitle";
import Solution from "./template/Solution";
import Tinding from "./template/Tiding";
import Vision from "./template/Vision";

export default function Home() {
  return (
    <div css={wrapper}>
      <HomeTitle />
      <Vision />
      <Solution />
      <History />
      <Awards />
      <Tinding />
      <Customer />
      <FinalContent page="Home" />
    </div>
  );
}

const wrapper = {
  width: "100%",
  display: "flex",
  flexDirection: "column" as const,
};
