/** @jsxImportSource @emotion/react */
import selectCircle from "../../../../assets/img/Home/History/select.svg";
import unSelectCircle from "../../../../assets/img/Home/History/unselect.svg";
import { useState } from "react";
import Year2024 from "./yearComponents/Year2024";
import Year2023 from "./yearComponents/Year2023";
import Year2022 from "./yearComponents/Year2022";
import Year2021 from "./yearComponents/Year2021";
import Year2020 from "./yearComponents/Year2020";
import Year2019 from "./yearComponents/Year2019";
import useResponsive from "../../../../hooks/useResponsive";

const texts = {
  contentPc: `서르는 2019년 설립 이후 다수의 인공지능데이터 가공 사업에 참여하였으며 이를 기반으로 자사 플랫폼을 개발하고 인정받아 왔습니다.`,
  contentMobile: `서르는 2019년 설립 이후 다수의
인공지능 데이터 가공 사업에 참여하였으며 이를 기반으로
자사 플랫폼을 개발하고 인정받아 왔습니다.`,
};

export default function History() {
  const { isTablet, isMobile } = useResponsive();
  const [isSelectYear, setSelectYear] = useState("2024");

  const handleYearClick = (year: string) => {
    setSelectYear(year);
  };

  const renderSelectedYearComponent = () => {
    switch (isSelectYear) {
      case "2024":
        return <Year2024 />;
      case "2023":
        return <Year2023 />;
      case "2022":
        return <Year2022 />;
      case "2021":
        return <Year2021 />;
      case "2020":
        return <Year2020 />;
      case "2019":
        return <Year2019 />;
      default:
        return null;
    }
  };

  return (
    <div css={historyStyle}>
      <div css={historyContent(isTablet)}>
        <div css={textBox}>
          <p css={textTitle}>HISTORY</p>
          {isTablet ? (
            <pre css={textSubtitle}>{texts.contentMobile}</pre>
          ) : (
            <pre css={textSubtitle}>{texts.contentPc}</pre>
          )}
        </div>
        <div css={style(isMobile)}>
          <div css={yearsListContainer}>
            <div css={timelineLine(isMobile)}></div>
            <div css={yearsList}>
              {["2024", "2023", "2022", "2021", "2020", "2019"].map((year) => (
                <div key={year} onClick={() => handleYearClick(year)} css={yearContainerStyle}>
                  <div css={isMobileGetYearStyle(isMobile, year, isSelectYear)}>{year}</div>
                  <img
                    src={isSelectYear === year ? selectCircle : unSelectCircle}
                    alt={isSelectYear === year ? "Selected" : "Unselected"}
                    css={isMobileCircleStyle(year, isMobile)}
                  />
                </div>
              ))}
            </div>
          </div>
          <div>{renderSelectedYearComponent()}</div>
        </div>
      </div>
    </div>
  );
}

const style = (isMobile: boolean) => ({
  display: "flex",
  flexDirection: "row" as const,
  gap: isMobile ? "20px" : "50px",
  width: "934px",
  paddingLeft: isMobile ? "none" : "47px",
  "@media (max-width: 1100px)": {
    width: "auto",
    alignSelf: "flex-start",
  },
});

const historyStyle = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column" as const,
  background: "black",
  minHeight: "100vh",
  height: "auto",
  gap: "50px",
};

const historyContent = (isTablet: boolean) => ({
  display: "flex",
  flexDirection: "column" as const,
  gap: "100px",
  alignItems: "center",
  height: "100%",
  padding: isTablet ? "150px 7%" : "150px 16.67%",
});

const textBox = {
  display: "flex",
  flexDirection: "column" as const,
  gap: "15px",
};

const textSubtitle = {
  fontSize: "18px",
  fontWeight: 500,
  lineHeight: "27px",
  textAlign: "center" as const,
  color: "white",
};

const textTitle = {
  fontSize: "32px",
  fontWeight: 600,
  lineHeight: "38.19px",
  textAlign: "center" as const,
  color: "white",
};

const yearsList = {
  display: "flex",
  flexDirection: "column" as const,
  gap: "80px",
  alignItems: "flex-end" as const,
};

const yearsListContainer = {
  position: "relative" as const,
  height: "100%",
  display: "flex",
  flexDirection: "column" as const,
};

const timelineLine = (isMobile: boolean) => ({
  position: "absolute" as const,
  left: isMobile ? "84%" : "88%",
  top: "10px",
  bottom: 0,
  width: "2px",
  height: "700px",
  backgroundColor: "#D9D9D9",
  zIndex: 0,
});

const circleStyle = (year: string) => ({
  width: `${30 - (2024 - parseInt(year)) * 2}px`,
  height: `${30 - (2024 - parseInt(year)) * 2}px`,
  paddingRight: `${0 + (2024 - parseInt(year))}px`,
  marginLeft: "25px",
  zIndex: 2,
});

const circleStyleMobile = (year: string) => ({
  width: `${24 - (2024 - parseInt(year)) * 2}px`,
  height: `${24 - (2024 - parseInt(year)) * 2}px`,
  paddingRight: `${0 + (2024 - parseInt(year))}px`,
  marginLeft: "15px",
  zIndex: 2,
});

const isMobileCircleStyle = (year: string, isMobile: boolean) =>
  isMobile ? circleStyleMobile(year) : circleStyle(year);

const yearContainerStyle = {
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
};

const yearText = {
  fontWeight: 600,
  textAlign: "center" as const,
  color: "#D9D9D9",
};

const getYearStyle = (year: string, isSelectYear: string) => ({
  ...yearText,
  fontSize: `${36 - (2024 - parseInt(year)) * 2}px`,
  lineHeight: `${43 - (2024 - parseInt(year)) * 2}px`,
  color: isSelectYear === year ? "#FFF" : "#D9D9D9",
});

const getYearStyleMobile = (year: string, isSelectYear: string) => ({
  ...yearText,
  fontSize: `${20 - (2024 - parseInt(year)) / 2}px`,
  lineHeight: `${30 - (2024 - parseInt(year)) * 2}px`,
});

const isMobileGetYearStyle = (isMobile: boolean, year: string, isSelectYear: string) =>
  isMobile ? getYearStyleMobile(year, isSelectYear) : getYearStyle(year, isSelectYear);
