import customer1 from "./1.svg";
import customer2 from "./2.svg";
import customer3 from "./3.svg";
import customer4 from "./4.svg";
import customer5 from "./5.svg";
import customer6 from "./6.svg";
import customer7 from "./7.svg";
import customer8 from "./8.svg";
import customer9 from "./9.svg";
import customer10 from "./10.svg";
import customer11 from "./11.svg";
import customer12 from "./12.svg";
import customer13 from "./13.svg";
import customer14 from "./14.svg";
import customer15 from "./15.svg";
import customer16 from "./16.svg";
import customer17 from "./17.svg";
import customer18 from "./18.svg";
import customer19 from "./19.svg";
import customer20 from "./20.svg";
import customer21 from "./21.svg";
import customer22 from "./22.svg";
import customer23 from "./23.svg";
import customer24 from "./24.svg";
import customer25 from "./25.svg";
import customer26 from "./26.svg";
import customer27 from "./27.svg";
import customer28 from "./28.svg";
import customer29 from "./29.svg";
import customer30 from "./30.svg";
import customer31 from "./31.svg";
import customer32 from "./32.svg";
import customer33 from "./33.svg";
import customer34 from "./34.svg";
import customer35 from "./35.svg";
import customer36 from "./36.svg";
import customer37 from "./37.svg";
import customer38 from "./38.svg";
import customer39 from "./39.svg";
import customer40 from "./40.svg";
import customer41 from "./41.svg";
import customer42 from "./42.svg";
import customer43 from "./43.svg";
import customer44 from "./44.svg";
import customer45 from "./45.svg";
import customer46 from "./46.svg";
import customer47 from "./47.svg";

export const customerImg = [
  customer1,
  customer2,
  customer3,
  customer4,
  customer5,
  customer6,
  customer7,
  customer8,
  customer9,
  customer10,
  customer11,
  customer12,
  customer13,
  customer14,
  customer15,
  customer16,
  customer17,
  customer18,
  customer19,
  customer20,
  customer21,
  customer22,
  customer23,
  customer24,
  customer25,
  customer26,
  customer27,
  customer28,
  customer29,
  customer30,
  customer31,
  customer32,
  customer33,
  customer34,
  customer35,
  customer36,
  customer37,
  customer38,
  customer39,
  customer40,
  customer41,
  customer42,
  customer43,
  customer44,
  customer45,
  customer46,
  customer47,
];
