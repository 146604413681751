import AiData from "./AiData";
import Home from "./Home";
import Contact from "./Contact";
import Medical from "./Medical";
import Recycle from "./Recycle";

import {
  Route,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import BaseLayout from "../component/Layout/BaseLayout";

const Root = () => (
  <Routes>
    <Route element={<BaseLayout />}>
      <Route path="/" element={<Home />} />
      <Route path="/AiData" element={<AiData />} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/Medical" element={<Medical />} />
      <Route path="/Recycle" element={<Recycle />} />
    </Route>
  </Routes>
);

export const router = createBrowserRouter(
  createRoutesFromElements(<Route path="*" element={<Root />} />)
);
