import contact1 from "./contact1.png";
import contact1Hover from "./contact1Hover.png";
import contact2 from "./contact2.png";
import contact2Hover from "./contact2Hover.png";
import datavoucher from "./datavoucher.png";
import datavoucherHover from "./datavoucherHover.png";
import answer from "./answer.png";
import answerHover from "./answerHover.png";
import companyIntroduction from "./companyIntroduction.png";
import companyIntroductionHover from "./companyIntroductionHover.png";
import productIntroduction from "./productIntroduction.png";
import productIntroductionHover from "./productIntroductionHover.png";
import engHoverBtn from "./engHoverBtn.png";
import recycle1 from "./recycle1.png";
import recycle1Hover from "./recycle1Hover.png";
import recycle2 from "./recycle2.png";
import recycle2Hover from "./recycle2Hover.png";
import recycle3 from "./recycle3.png";
import recycle3Hover from "./recycle3Hover.png";
import recycle4 from "./recycle4.png";
import recycle4Hover from "./recycle4Hover.png";

export const ButtonImg = {
  contact1: contact1,
  contact1Hover: contact1Hover,
  contact2: contact2,
  contact2Hover: contact2Hover,
  데이터바우처: datavoucher,
  데이터바우처Hover: datavoucherHover,
  문의하기: answer,
  문의하기Hover: answerHover,
  회사소개서다운로드: companyIntroduction,
  회사소개서Hover: companyIntroductionHover,
  제품소개서다운로드: productIntroduction,
  제품소개서Hover: productIntroductionHover,
  eng호버버튼: engHoverBtn,
  recycle1: recycle1,
  recycle1Hover: recycle1Hover,
  recycle2: recycle2,
  recycle2Hover: recycle2Hover,
  recycle3: recycle3,
  recycle3Hover: recycle3Hover,
  recycle4: recycle4,
  recycle4Hover: recycle4Hover,
};
