import AiBackgoundImg from "../img/AiData/background.jpeg";
import consulting from "../img/AiData/consulting.png";
import ai from "../img/AiData/ai.png";
import suredData from "../img/AiData/suredData.png";
import project from "../img/AiData/multipleProject.png";
import logoImg from "../img/header/logo.svg";
import hoverLogo from "../img/header/hoverLogo.svg";
import youtube from "../img/footer/youtube.svg";
import instagram from "../img/footer/instagram.svg";
import naver from "../img/footer/naver.svg";
import HomeTitle from "../img/Home/title.svg";
import feature from "../img/Home/feature.svg";
import innovatory from "../img/Home/innovatory.svg";
import thinking from "../img/Home/thinking.svg";
import solutionAiData from "../img/Home/solutionAiData.png";
import solutionRecycle from "../img/Home/solutionRecycle.png";
import solutionMedical from "../img/Home/solutionMedical.png";
import download from "../img/Home/download.svg";
import Awards1 from "../img/Home/Awards/awards1.png";
import Awards2 from "../img/Home/Awards/awards2.png";
import Awards3 from "../img/Home/Awards/awards3.png";
import Awards4 from "../img/Home/Awards/awards4.png";
import Awards5 from "../img/Home/Awards/awards5.png";
import Awards6 from "../img/Home/Awards/awards6.png";
import Awards7 from "../img/Home/Awards/awards7.png";
import Awards8 from "../img/Home/Awards/awards8.png";

import BoundingBox from "../img/AiData/Data processing method/BoundingBox.png";
import Cuboid from "../img/AiData/Data processing method/Cuboid.png";
import Point from "../img/AiData/Data processing method/Point.png";
import PolyLine from "../img/AiData/Data processing method/PolyLine.png";
import Polygon from "../img/AiData/Data processing method/Polygon.png";
import Semantic from "../img/AiData/Data processing method/Semantic.png";

import AiProject1 from "../img/AiData/AiProject/AiProject1.png";
import AiProject2 from "../img/AiData/AiProject/AiProject2.png";
import AiProject3 from "../img/AiData/AiProject/AiProject3.png";
import AiProject4 from "../img/AiData/AiProject/AiProject4.png";

import DataVoucher1 from "../img/AiData/DataVoucher/01.jpg";
import DataVoucher2 from "../img/AiData/DataVoucher/02.jpg";
import DataVoucher3 from "../img/AiData/DataVoucher/03.jpg";
import DataVoucher4 from "../img/AiData/DataVoucher/04.jpg";
import DataVoucher5 from "../img/AiData/DataVoucher/05.jpg";
import DataVoucher6 from "../img/AiData/DataVoucher/06.jpg";
import DataVoucher7 from "../img/AiData/DataVoucher/07.jpg";
import DataVoucher8 from "../img/AiData/DataVoucher/08.jpg";
import DataVoucher9 from "../img/AiData/DataVoucher/09.jpg";
import DataVoucher10 from "../img/AiData/DataVoucher/10.jpg";
import DataVoucher11 from "../img/AiData/DataVoucher/11.jpg";
import DataVoucher12 from "../img/AiData/DataVoucher/12.jpg";

import RecycleBackground from "../img/Recycle/recycleBackground.jpg";

import Wimple1 from "../img/Recycle/Wimple/1.png";
import Wimple2 from "../img/Recycle/Wimple/2.png";
import Wimple3 from "../img/Recycle/Wimple/3.png";
import Wimple4 from "../img/Recycle/Wimple/4.png";
import Wimple5 from "../img/Recycle/Wimple/5.png";
import Wimple6 from "../img/Recycle/Wimple/6.png";
import processImage from "../img/Recycle/Wimple/processImage.png";
import weeVisualImage from "../img/Recycle/Wimple/wee_visual.png";
import recycleGradation from "../img/Recycle/gradation.png";

import Point1 from "../img/Recycle/KeyPoint/Point1.png";
import Point2 from "../img/Recycle/KeyPoint/Point2.png";
import Point3 from "../img/Recycle/KeyPoint/Point3.png";

import ProductLine1 from "../img/ProductLine/1.png";
import ProductLine2 from "../img/ProductLine/2.png";
import ProductLine3 from "../img/ProductLine/3.png";

import MedicalBackground from "../img/Medical/medicalBackground.jpg";
import Img1 from "../img/Medical/img1.png";
import EngBtn from "../Button/engBtn.png";

import LesionDetection1 from "../img/Medical/medical_ai/01/1.png";
import LesionDetection2 from "../img/Medical/medical_ai/01/2.png";
import LesionDetection3 from "../img/Medical/medical_ai/01/3.png";
import LesionDetection4 from "../img/Medical/medical_ai/01/4.png";
import TransitionClassification from "../img/Medical/medical_ai/02/1.png";
import VisualizationScale from "../img/Medical/medical_ai/03/5.png";
import BottomImg1 from "../img/Medical/medical_ai/bottomImg1.png";
import BottomImg2 from "../img/Medical/medical_ai/bottomImg2.png";
import Graph1 from "../img/Medical/graph/graph1.png";
import Graph2 from "../img/Medical/graph/graph2.png";

import BothArrow from "../img/Medical/Compatibility/BothArrow.png";
import LeftImage from "../img/Medical/Compatibility/left.png";
import RightImage from "../img/Medical/Compatibility/right.png";
import homeInquiry from "../img/final/homeInquiry.png";
import aiDataInquiry from "../img/final/aiDataInquiry.png";
import recycleInquiry from "../img/final/recycleInquiry.png";
import medicalInquiry from "../img/final/medicalInquiry.png";

export const AiDataImg = {
  배경: AiBackgoundImg,
  고객맞춤형컨설팅: consulting,
  다수의프로젝트진행: project,
  검증된데이터전문가: suredData,
  인공지능개발전문가: ai,
};

export const Icon = {
  로고: logoImg,
  유튜브: youtube,
  인스타그램: instagram,
  네이버: naver,
  호버로고: hoverLogo,
};

export const HomeImg = {
  배경: HomeTitle,
  혁신적인기업: feature,
  미래지향적인기업: innovatory,
  사람을생각하는기업: thinking,
  솔루션AiData: solutionAiData,
  솔루션Recycle: solutionRecycle,
  솔루션Medical: solutionMedical,
  다운로드: download,
};

type AwardImgType = {
  [key: string]: string;
};

export const AwardImg: AwardImgType = {
  그랜드챌린지2위: Awards1,
  데이터바우처: Awards2,
  IR피칭최우수상: Awards3,
  인공지능온라인경연: Awards4,
  DX챌린지우수상: Awards5,
  산학연협력선도대학: Awards6,
  BIZ프로젝트공모전: Awards7,
  생산성CEO대상: Awards8,
};

export const DataProcessing = {
  BoundingBox,
  Cuboid,
  PolyLine,
  Polygon,
  Point,
  Semantic,
};

export const AiProject = {
  AiProject1,
  AiProject2,
  AiProject3,
  AiProject4,
};

export const DataVaucher = {
  DataVoucher1,
  DataVoucher2,
  DataVoucher3,
  DataVoucher4,
  DataVoucher5,
  DataVoucher6,
  DataVoucher7,
  DataVoucher8,
  DataVoucher9,
  DataVoucher10,
  DataVoucher11,
  DataVoucher12,
};

export const RecycleImage = {
  background: RecycleBackground,
  recycleGradation,
  WimpleImgProcess: {
    Wimple1,
    Wimple2,
    Wimple3,
    Wimple4,
    Wimple5,
    Wimple6,
    processImage,
    weeVisualImage,
  },
  KeyPoint: {
    Point1,
    Point2,
    Point3,
  },
  ProductLine: {
    ProductLine1,
    ProductLine2,
    ProductLine3,
  },
};

export const MedicalImage = {
  MedicalBackground,
  Img1,
  EngBtn,
  ai: {
    LesionDetection1,
    LesionDetection2,
    LesionDetection3,
    LesionDetection4,
    TransitionClassification,
    VisualizationScale,
    BottomImg1,
    BottomImg2,
    Graph1,
    Graph2,
  },
  Compatibility: {
    BothArrow,
    LeftImage,
    RightImage,
  },
};

export const final = {
  Home: homeInquiry,
  AIData: aiDataInquiry,
  Recycle: recycleInquiry,
  Medical: medicalInquiry,
};
