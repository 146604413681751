/** @jsxImportSource @emotion/react */
import { useState } from "react";
import ImageComponent from "./ImageComponent";

export default function MobileSection3() {
  const [isClick, setIsClick] = useState<number>(0);
  const description = [
    {
      title: "Lesion Detection",
      description: "AI를 통해 소장 내  궤양, 용종, 림프관 확장, 출혈 검출이 가능",
    },
    {
      title: "Transition Classification",
      description: "AI를 통해 소장 시작 구간, 소장 끝 구간을 추정 가능",
    },
    {
      title: "Visualization Scale",
      description: "AI를 통해 장 정결도 측정 가능",
    },
  ];
  return (
    <div css={layout}>
      <div css={header}>
        <p
          css={menu(isClick === 0)}
          onClick={() => {
            setIsClick(0);
          }}
        >
          병변 검출
        </p>
        <p
          css={menu(isClick === 1)}
          onClick={() => {
            setIsClick(1);
          }}
        >
          소장 구간
        </p>
        <p
          css={menu(isClick === 2)}
          onClick={() => {
            setIsClick(2);
          }}
        >
          장 정결도 측정
        </p>
      </div>
      <div css={textBox}>
        <p>{description[isClick].title}</p>
        <p>{description[isClick].description}</p>
      </div>
      <ImageComponent isClick={isClick} isMobile={true} />
    </div>
  );
}

const layout = {
  width: "285px",
  display: "flex",
  flexDirection: "column" as const,
};

const header = {
  display: "flex",
  flexDirection: "row" as const,
  justifyContent: "space-around",
  alignItems: "center",
  borderBottom: "1px solid #DFDFDF",
  paddingBottom: "8px",
};

const menu = (isClick: boolean) => ({
  color: "white",
  fontWeight: isClick ? "700" : "400",
  textDecoration: isClick ? "underLine" : "",
});

const textBox = { color: "white", padding: "8px" };
