/** @jsxImportSource @emotion/react */
import selectCircle from "../../../../../assets/img/Home/History/select.svg";
import unSelectCircle from "../../../../../assets/img/Home/History/unselect.svg";

interface PropsType {
  handleClick: (index: number) => void;
  isClick: number;
}

export default function LineHeader({ isClick, handleClick }: PropsType) {
  return (
    <div css={line}>
      <img
        css={circle1}
        onClick={() => handleClick(0)}
        src={isClick === 0 ? selectCircle : unSelectCircle}
        alt="circle1"
      />
      <img
        css={circle2}
        onClick={() => handleClick(1)}
        src={isClick === 1 ? selectCircle : unSelectCircle}
        alt="circle2"
      />
      <img
        css={circle3}
        onClick={() => handleClick(2)}
        src={isClick === 2 ? selectCircle : unSelectCircle}
        alt="circle3"
      />
    </div>
  );
}

const line = {
  position: "relative" as const,
  width: "1px",
  height: "492px",
  borderLeft: "1px solid white",
};

const circle = {
  width: "30px",
  height: "30px",
  cursor: "pointer",
  position: "absolute" as const,
  left: "-15px",
};

const circle1 = {
  ...circle,
  top: "-15px",
};

const circle2 = {
  ...circle,
  top: "50%",
  transform: "translate(0%, -50%)",
};

const circle3 = {
  ...circle,
  top: "100%",
  transform: "translate(0%, -15px)",
};
