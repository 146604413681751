/** @jsxImportSource @emotion/react */
import useResponsive from "../../../../../hooks/useResponsive";

export default function Year2023() {
  const { isMobile } = useResponsive();
  return (
    <div css={yearStyle}>
      <div css={textBox(isMobile)}>
        <p css={numberText(isMobile)}>12</p>
        <div css={Texts}>
          <p css={text(isMobile)}>NeurlPS2023 머신언러닝 챌린지상위 1% 성적 획득</p>
          <p css={text(isMobile)}>공공장소용 분리수거기 Wimple P 출시</p>
        </div>
      </div>
      <div css={textBox(isMobile)}>
        <p css={numberText(isMobile)}>10</p>
        <p css={text(isMobile)}>TECH밸리 기업 선정</p>
      </div>
      <div css={textBox(isMobile)}>
        <p css={numberText(isMobile)}>09</p>
        <p css={text(isMobile)}>부산경제진흥원, 공공기관 테스트베드 지원사업 참여 (부산시설공단 테스트베드 진행)</p>
      </div>
      <div css={textBox(isMobile)}>
        <p css={numberText(isMobile)}>07</p>
        <div css={Texts}>
          <p css={text(isMobile)}>NIA, 헬스케어 분야 인공지능 학습용 데이터 구축 사업 참여 *우수사례 선정</p>
          <p css={text(isMobile)}>벤처기업인증</p>
        </div>
      </div>
      <div css={textBox(isMobile)}>
        <p css={numberText(isMobile)}>06</p>
        <p css={text(isMobile)}>(재)부산정보사회진흥원, AI 기업 사업화 지원사업 참여</p>
      </div>
      <div css={textBox(isMobile)}>
        <p css={numberText(isMobile)}>04</p>
        <p css={text(isMobile)}>한국연구산업협회, 연구산업육성사업·연구개발서비스바우처 기관 선정</p>
      </div>
      <div css={textBox(isMobile)}>
        <p css={numberText(isMobile)}>03</p>
        <div css={Texts}>
          <p css={text(isMobile)}>카자흐스탄 Interna Clinic 캡슐내시경 진단보조시스템 시범도입 계약</p>
          <p css={text(isMobile)}>상표등록 (윔플빈 BI_logo, 40-1997421, 40-1997422)</p>
          <p css={text(isMobile)}>상표등록 (WIMPLE BIN, 윔플빈_txt, 40-1997419, 40-1997420)</p>
          <p css={text(isMobile)}>생활폐기물 선별 알고리즘 WEE 출시</p>
        </div>
      </div>
      <div css={textBox(isMobile)}>
        <p css={numberText(isMobile)}>02</p>
        <div css={Texts}>
          <p css={text(isMobile)}>소프트웨어사업자 인증</p>
          <p css={text(isMobile)}>여성기업인증</p>
        </div>
      </div>
      <div css={textBox(isMobile)}>
        <p css={numberText(isMobile)}>01</p>
        <div css={Texts}>
          <p css={text(isMobile)}>중소벤처기업부, 소셜벤처기업 인증</p>
          <p css={text(isMobile)}>과기부, 전문연구사업자 인증</p>
        </div>
      </div>
    </div>
  );
}

const yearStyle = {
  display: "flex",
  flexDirection: "column" as const,
  gap: "10px",
};

const textBox = (isMobile: boolean) => ({
  display: "flex",
  flexDirection: "row" as const,
  gap: isMobile ? "15px" : "30px",
});

const Texts = {
  display: "flex",
  flexDirection: "column" as const,
  gap: "10px",
};

const numberText = (isMobile: boolean) => ({
  fontSize: isMobile ? "14px" : "20px",
  fontWeight: 700,
  lineHeight: "23.87px",
  textAlign: "center" as const,
  color: "white",
});

const text = (isMobile: boolean) => ({
  fontSize: isMobile ? "14px" : "18px",
  fontWeight: 400,
  lineHeight: "27px",
  textAlign: "left" as const,
  color: "white",
});
