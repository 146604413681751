/** @jsxImportSource @emotion/react */
import useBasePadding from "../../../../hooks/useBasePadding";
import { PRODUCT_LINE_STRUCTURE } from "./structure";
import useResponsive from "../../../../hooks/useResponsive";
import { ButtonImg } from "../../../../assets/Button/buttons";
import { useState } from "react";

const texts = {
  content: `깨끗한 지구를 만들어가기 위해서 분리배출은 폐기물이 배출되는 모든 장소에서 이루어져야 합니다. 
서르는 다양한 장소와 사용자의 목적에 맞게 WIMPLE을 다양한 제품군으로 개발하였습니다.
양질의 자원을 회수하여 재활용률을 증가시켜 탄소 배출을 줄이고자 하는 목적은 동일하지만, 
폐기물이 배출되는 장소와 사용자의 목적에 따라 필요한 제품의 조건이 달라질 수 있습니다.`,
  contentMobile: `깨끗한 지구를 만들어가기 위해서 분리배출은
폐기물이 배출되는 모든 장소에서 이루어져야 합니다. 
서르는 다양한 장소와 사용자의 목적에 맞게
WIMPLE을 다양한 제품군으로 개발하였습니다.
양질의 자원을 회수하여 재활용률을 증가시켜
탄소 배출을 줄이고자 하는 목적은 동일하지만, 
폐기물이 배출되는 장소와 사용자의 목적에 따라
필요한 제품의 조건이 달라질 수 있습니다.`,
};

export default function ProductLine() {
  const { GOLBAL_PADDING } = useBasePadding();
  const { isPc } = useResponsive();
  const [productBtn, setProductBtn] = useState<string>(ButtonImg.제품소개서다운로드);
  const [recycle1Btn, setRecycle1Btn] = useState<string>(ButtonImg.recycle1);
  return (
    <div css={wrapper(GOLBAL_PADDING, !isPc)}>
      <div css={header(!isPc)}>
        <div css={!isPc ? { marginBottom: "40px" } : { marginBottom: "100px" }}>
          <p css={title(isPc)}>WIMPLE 제품군</p>
          {isPc ? (
            <pre css={description(!isPc)}>{texts.content}</pre>
          ) : (
            <pre css={description(!isPc)}>{texts.contentMobile}</pre>
          )}
        </div>
        <div
          css={{
            display: "flex",
            flexDirection: "column" as const,
            gap: "6px",
          }}
        >
          <div
            css={{
              display: "flex",
              flexDirection: "column" as const,
              alignItems: "center",
              textAlign: "center" as const,
              gap: "11px",
            }}
          >
            <a
              href="https://drive.google.com/file/d/150Sr1iTukSPg0hmSgKjG2ga9t0aNWoz9/view?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={productBtn}
                alt="제품소개서 다운로드"
                css={buttonStyle}
                onMouseEnter={() => setProductBtn(ButtonImg.제품소개서Hover)}
                onMouseLeave={() => setProductBtn(ButtonImg.제품소개서다운로드)}
              />
            </a>
            <a href="https://venture.g2b.go.kr:8321/index.jsp" target="_blank" rel="noopener noreferrer">
              <img
                src={recycle1Btn}
                alt="제품구매 바로가기"
                css={buttonStyle}
                onMouseEnter={() => setRecycle1Btn(ButtonImg.recycle1Hover)}
                onMouseLeave={() => setRecycle1Btn(ButtonImg.recycle1)}
              />
              <p css={{ color: "white", fontSize: "11px", paddingTop: "10px" }}>
                *조달청 벤처나라에서 주식회사 서르를 검색해주세요.
              </p>
            </a>
          </div>
        </div>
      </div>
      <div>
        {!isPc &&
          PRODUCT_LINE_STRUCTURE.map((item) => {
            return (
              <div css={mobileContent(item.img)}>
                <div
                  css={{
                    display: "flex",
                    width: "285px",
                    flexDirection: "column",
                    padding: "8px",
                    gap: "8px",
                    justifyContent: "space-around",
                    alignItems: "flex-start",
                    height: "100%",
                  }}
                >
                  <div>
                    <p css={{ ...itemTitle, fontSize: "24px" }}>{item.title}</p>
                    <p css={{ ...itemDescription, fontSize: "12px" }}>{item.dscription}</p>
                  </div>
                  <div>
                    <a href={item.link1} target="_blank" rel="noopener noreferrer">
                      <img src={item.btn1} alt="WIMPLE BIN - M 카탈로그 다운로드" css={MbuttonStyle} />
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        {isPc &&
          PRODUCT_LINE_STRUCTURE.map((item) => {
            const ImgComponent = () => {
              return (
                <img
                  css={{
                    width: "640px",
                    height: "400px",
                    objectFit: "cover",
                  }}
                  src={item.img}
                  alt={item.title}
                />
              );
            };
            const TextCompoonent = () => {
              return (
                <div
                  css={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "50px",
                    gap: "15px",
                    justifyContent: "center",
                    alignItems: item.direction === "right" ? "flex-end" : "flex-start",
                  }}
                >
                  <p css={itemTitle}>{item.title}</p>
                  <p css={itemDescription}>{item.dscription}</p>
                  <a href={item.link1} target="_blank" rel="noopener noreferrer">
                    <img
                      src={item.btn1}
                      alt="WIMPLE BIN - M 카탈로그 다운로드"
                      css={buttonStyle}
                      onMouseEnter={(e) => (e.currentTarget.src = item.btn2)}
                      onMouseLeave={(e) => (e.currentTarget.src = item.btn1)}
                    />
                  </a>
                  <a href={item.link2} target="_blank" rel="noopener noreferrer"></a>
                </div>
              );
            };
            if (item.direction === "left") {
              return (
                <div css={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                  <ImgComponent />
                  <TextCompoonent />
                </div>
              );
            }
            return (
              <div
                css={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  alignItems: "center",
                }}
              >
                <TextCompoonent />
                <ImgComponent />
              </div>
            );
          })}
      </div>
    </div>
  );
}

const wrapper = (padding: string, isMobile: boolean) => ({
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
  paddingLeft: padding,
  paddingRight: padding,
  background: "black",
  marginTop: isMobile ? "100px" : "200px",
  height: "auto",
  marginBottom: isMobile ? "48px" : "",
});

const header = (isMobile: boolean) => ({
  width: "100%",
  display: "flex",
  flexDirection: isMobile ? ("column" as const) : ("row" as const),
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: isMobile ? "24px" : "",
  maxWidth: "1280px",
});

const title = (isPc: boolean) => ({
  color: "white",
  fontSize: "32px",
  fontWeight: "600",
  marginBottom: "15px",
  textAlign: isPc ? ("left" as const) : ("center" as const),
});

const description = (isMobile: boolean) => ({
  color: "white",
  fontSize: isMobile ? "13px" : "18px",
  fontWeight: "500",
  lineHeight: isMobile ? "20px" : "27px",
  textAlign: isMobile ? ("center" as const) : ("left" as const),
});

const itemTitle = {
  color: "white",
  fontSize: "32px",
  fontWeight: "600",
  lineHeight: "27px",
};

const itemDescription = {
  color: "white",
  fontSize: "18px",
  fontWeight: "500",
  lineHeight: "27px",
};

const buttonStyle = {
  width: "300px",
  height: "65px",
  cursor: "pointer",
};

const mobileContent = (url: string) => ({
  width: "285px",
  height: "200px",
  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${url})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
});

const MbuttonStyle = {
  width: "180px",
  height: "40px",
  cursor: "pointer",
};
