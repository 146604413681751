/** @jsxImportSource @emotion/react */

import FinalContent from "../../component/final/finalContent";
import MedicalMain from "./template/MedicalMain";
import Section1 from "./template/Section1";
import Section2 from "./template/Section2";
import Section3 from "./template/Section3";
import Section4 from "./template/Section4";
import Section5 from "./template/Section5";
import Section6 from "./template/Section6";

export default function Medical() {
  return (
    <div css={wrapper}>
      <MedicalMain />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <FinalContent page="Medical" />
    </div>
  );
}

const wrapper = {
  display: "flex",
  flexDirection: "column" as const,
  width: "100%",
};
