/** @jsxImportSource @emotion/react */
import { useMemo, useState } from "react";
import { final } from "../../assets/img/img";
import useHandleHistory from "../../hooks/useHandleHistory";
import useResponsive from "../../hooks/useResponsive";
import { ButtonImg } from "../../assets/Button/buttons";

interface Props {
  page: "Home" | "AIData" | "Recycle" | "Medical";
}

const PageData = {
  Home: {
    title: `서르에 대해 더 궁금하신가요?`,
    titleTablet: `서르에 대해 더 궁금하신가요?`,
    subTitle: `문의를 남겨주시면 상세하게 답변 드리겠습니다.`,
    subTitleTablet: `문의를 남겨주시면 상세하게 답변 드리겠습니다.`,
    secondButton: false,
    bgImg: final.Home,
  },
  AIData: {
    title: `서르와 함께 2024 데이터 · AI 정부지원 사업에 참여하세요.`,
    titleTablet: `서르와 함께 2024 데이터 · 
AI 정부지원 사업에 참여하세요.`,
    subTitle: `데이터 바우처 최대 7,000만원 AI 바우처 최대 3억원 정부 지원`,
    subTitleTablet: `데이터 바우처 최대 7,000만원 AI 바우처
최대 3억원 정부 지원`,
    secondButton: true,
    bgImg: final.AIData,
  },
  Recycle: {
    title: `WIMPLE에 대해 더 궁금하신가요?`,
    titleTablet: `WIMPLE에 대해 더 궁금하신가요?`,
    subTitle: `문의를 남겨주시면 상세하게 답변 드리겠습니다.`,
    subTitleTablet: `문의를 남겨주시면 상세하게 답변 드리겠습니다.`,
    secondButton: false,
    bgImg: final.Recycle,
  },
  Medical: {
    title: `CAPTOS에 대해 더 궁금하신가요?`,
    titleTablet: `CAPTOS에 대해 더 궁금하신가요?`,
    subTitle: `문의를 남겨주시면 상세하게 답변 드리겠습니다.`,
    subTitleTablet: `문의를 남겨주시면 상세하게 답변 드리겠습니다.`,
    secondButton: false,
    bgImg: final.Medical,
  },
};

export default function FinalContent({ page }: Props) {
  const { isTablet, isMobile } = useResponsive();
  const { handleHistoryPush } = useHandleHistory();

  const { title, titleTablet, subTitle, subTitleTablet, secondButton, bgImg } = PageData[page];
  const [logoSrc, setLogoSrc] = useState<string>(ButtonImg.문의하기);
  const [logo2Src, setLogo2Src] = useState<string>(ButtonImg.데이터바우처);

  const wrapperStyle = useMemo(
    () =>
      ({
        boxSizing: "border-box",
        height: isMobile ? "auto" : "300px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: isTablet ? (isMobile ? "7%" : "75px 7%") : "75px 21.88%",
        background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${bgImg})`,
        backgroundSize: page === "Home" ? (isMobile ? "120%" : "cover") : "cover",
        backgroundPosition: page === "Home" ? (isMobile ? "center 100%" : "center 58%") : "center",
        backgroundRepeat: "no-repeat",
      } as const),
    [bgImg, isTablet, isMobile, page]
  );

  const handleClick = () => handleHistoryPush("/Contact");

  return (
    <div css={wrapperStyle}>
      <div css={contentStyle(isMobile)}>
        <div css={textBox}>
          {isTablet ? (
            <pre css={titleTextStyle(isTablet, isMobile)}>{titleTablet}</pre>
          ) : (
            <pre css={titleTextStyle(isTablet, isMobile)}>{title}</pre>
          )}
          {isTablet ? (
            <pre css={contentTextStyle(isTablet, isMobile)}>{subTitleTablet}</pre>
          ) : (
            <pre css={contentTextStyle(isTablet, isMobile)}>{subTitle}</pre>
          )}
        </div>
        <div css={buttonBoxStyle(isMobile)}>
          <img
            src={logoSrc}
            alt="문의하기"
            css={buttonStyle}
            onClick={handleClick}
            onMouseEnter={() => setLogoSrc(ButtonImg.문의하기Hover)}
            onMouseLeave={() => setLogoSrc(ButtonImg.문의하기)}
          />
          {secondButton && (
            <a href="https://www.kdata.or.kr/kr/contents/voucher_01/view.do#" target="_blank" rel="noopener noreferrer">
              <img
                src={logo2Src}
                alt="데이터바우처"
                css={buttonStyle}
                onMouseEnter={() => setLogo2Src(ButtonImg.데이터바우처Hover)}
                onMouseLeave={() => setLogo2Src(ButtonImg.데이터바우처)}
              />
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

const contentStyle = (isMobile: boolean) =>
  ({
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    gap: isMobile ? "20px" : "none",
  } as const);

const textBox = {
  display: "flex",
  flexDirection: "column",
  gap: "15px",
} as const;

const titleTextStyle = (isTablet: boolean, isMobile: boolean) =>
  ({
    fontSize: isTablet ? (isMobile ? "27px" : "30px") : "32px",
    fontWeight: 600,
    lineHeight: "38.19px",
    textAlign: isMobile ? "center" : "left",
    color: "white",
  } as const);

const contentTextStyle = (isTablet: boolean, isMobile: boolean) =>
  ({
    fontSize: isTablet ? (isMobile ? "15px" : "18px") : "20px",
    fontWeight: 700,
    lineHeight: "23.87px",
    textAlign: isMobile ? "center" : "left",
    color: "white",
  } as const);

const buttonBoxStyle = (isMobile: boolean) =>
  ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: isMobile ? "10px" : "20px",
  } as const);

const buttonStyle = {
  width: "180px",
  height: "65px",
  cursor: "pointer",
};
