/** @jsxImportSource @emotion/react */
import { keyframes } from "@emotion/react";
import { awardsData } from "./constant";
import { useState, useEffect } from "react";

export default function Awards() {
  const [imagesLoad, setImagesLoad] = useState(false);

  useEffect(() => {
    const loadImages = async () => {
      const promises = awardsData.map((award) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = award.image;
          img.onload = resolve;
        });
      });
      await Promise.all(promises);
      setImagesLoad(true);
    };

    loadImages();
  }, []);
  return (
    <div css={awardsStyle}>
      <div css={historyContent}>
        <div css={textBox}>
          <p css={textTitle}>AWARDS</p>
          <p css={textSubtitle}>
            다수의 수상 경력이 서르의 기술력을 증명합니다.
          </p>
        </div>
        <div css={slideContainer}>
          <div css={slideStyle}>
            {imagesLoad &&
              [...awardsData, ...awardsData].map((award) => (
                <div css={slideBoxStyle} key={award.title}>
                  <img src={award.image} alt={award.title} css={imageStyle} />
                  <div>
                    <p css={slideTextStyle}>{award.title}</p>
                    <p css={slideTextStyle}>{award.premier}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const infiniteSlide = keyframes({
  "0%": {
    transform: "translateX(0)",
  },
  "100%": {
    transform: "translateX(-50%)",
  },
});

const awardsStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column" as const,
  background: "black",
  height: "auto",
  paddingBottom: "200px",
};

const historyContent = {
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
  width: "100%",
  height: "100%",
};

const textBox = {
  display: "flex",
  flexDirection: "column" as const,
  gap: "15px",
};

const textSubtitle = {
  fontFamily: "Pretendard, sans-serif",
  fontSize: "18px",
  fontWeight: 500,
  lineHeight: "27px",
  textAlign: "center" as const,
  color: "white",
};

const textTitle = {
  fontFamily: "Pretendard, sans-serif",
  fontSize: "32px",
  fontWeight: 600,
  lineHeight: "38.19px",
  textAlign: "center" as const,
  color: "white",
};

const slideContainer = {
  display: "flex",
  marginTop: "100px",
  overflow: "hidden",
  width: "100%",
  willChange: "transform",
};

const slideStyle = {
  display: "flex",
  gap: "20px",
  paddingRight: "20px",
  animation: `${infiniteSlide} 20s linear infinite`,
};

const imageStyle = {
  width: "auto",
  height: "300px",
} as const;

const slideBoxStyle = {
  boxSizing: "border-box" as const,
  display: "flex",
  flexDirection: "column" as const,
  gap: "20px",
  maxWidth: "374px",
  minWidth: "250px",
  alignItems: "center" as const,
};

const slideTextStyle = {
  fontSize: "18px",
  fontWeight: 400,
  lineHeight: "27px",
  textAlign: "center" as const,
  color: "white",
};
