/** @jsxImportSource @emotion/react */
import FinalContent from "../../component/final/finalContent";
import Algorithm from "./template/Algorithm";
import DeliveryMethod from "./template/DeliveryMethod";
import KeyPoint from "./template/KeyPoint";
import ProductLine from "./template/ProductLine";
import RecycleMain from "./template/RecycleMain";
import WimpleDescription from "./template/WimpleDescription";

export default function Recycle() {
  return (
    <div css={wrapper}>
      <RecycleMain />
      <WimpleDescription />
      <Algorithm />
      <KeyPoint />
      <DeliveryMethod />
      <ProductLine />
      <FinalContent page="Recycle" />
    </div>
  );
}

const wrapper = {
  display: "flex",
  flexDirection: "column" as const,
  width: "100%",
};
